import { format } from 'date-fns';
import React from 'react';
import './Footer.scss';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import useDetectTenantType from '../Custom/useDetectTenantType';

export default function Footer() {
  const year = format(new Date(), 'yyyy');
  const [tenantType] = useDetectTenantType();

  return (
    <footer>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ul>
              {tenantType === 'squirrel' ? (
                <li><a href="mailto:squirrel@vetpartners.co.uk" target="_blank" rel="noopener noreferrer" title="Email squirrel@vetpartners.co.uk (opens in a new tab/window)">Support</a></li>
              ) : (
                <li><a href="mailto:support@tillr.io" target="_blank" rel="noopener noreferrer" title="Email support@tillr.io (opens in a new tab/window)">Support</a></li>
              )}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p>
              &copy; Telamon Software Ltd {year}
            </p>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
