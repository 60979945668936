import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';
import { useGetUsersAdmin } from '../../../apollo-hooks';
import ActionButtons from '../../Shared/ActionButtons';
import HorizontalLine from '../../Shared/HorizontalLine';
import LinkButton from '../../Shared/LinkButton';
import AdminBreadcrumbs from '../AdminBreadcrumbs';
import AdminNavigation from '../AdminNavigation';
import PendingUsers from './PendingUsers';
import RegisteredUsers from './RegisteredUsers';
import TitlePrefix from '../../Shared/TitlePrefix';
import InfoTextBlock from '../../Shared/InfoTextBlock';
import useDetectTenantType from '../../Custom/useDetectTenantType';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    margin: theme.spacing(2, 0),
  },
}));

export default function Users() {
  const classes = useStyles();
  const { loading, error, data } = useGetUsersAdmin();
  const [tenantType] = useDetectTenantType();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Users
            </>
          </Typography>
          <AdminBreadcrumbs title="Users" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />

      <AdminNavigation path="/admin/users" />

      {tenantType === 'squirrel' && (
      <>
        <InfoTextBlock>
          Colleagues&apos; access to Squirrel is created or amended on this page.
          <br />
          <strong>Please only use if you’re trained to do so.</strong>
          <br />
          All changes in agreement with central office compliance team.
          <br />
          Pending and registered users are listed.
          <br />
          Only registered user accounts can be edited.
          <br />
          Invite new users with the yellow button in the top right corner.
        </InfoTextBlock>
      </>
      )}

      <HorizontalLine margin={[2, 0, 2, 0]} />

      <ActionButtons>
        <LinkButton
          to="/admin/users/add"
          startIcon={<PersonAddIcon />}
          aria-label="add-user"
          tooltip="Send invite to new user"
        >
          Invite new user
        </LinkButton>
      </ActionButtons>

      <HorizontalLine margin={[2, 0, 2, 0]} />

      <Typography className={classes.title} variant="h6">
        Pending invitations
      </Typography>

      <PendingUsers error={error} loading={loading} data={data?.pendingInvitations} />

      <Typography className={classes.title} variant="h6">
        Registered users
      </Typography>

      <RegisteredUsers error={error} loading={loading} data={data?.usersAdmin} />

    </>
  );
}
