/* eslint-disable consistent-return */
import { Checkbox } from '@material-ui/core';
import { format } from 'date-fns';
import MUIDataTable, { FilterType, MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DataColumn, DataRow } from '../utils';

interface IProps {
  reportName: string;
  columns: DataColumn[],
  data: DataRow[];
  initialFilterOn?: {
    column: string;
    value: string | number | boolean;
  };
}

export default function TableView(props: IProps) {
  const {
    reportName,
    columns,
    data,
    initialFilterOn,
  } = props;
  let filteredData = null;
  let filteredColumns = null;

  if (typeof initialFilterOn !== 'undefined') {
    // eslint-disable-next-line array-callback-return
    filteredData = data.filter((row) => {
      if (row[initialFilterOn.column] === initialFilterOn.value) {
        // eslint-disable-next-line no-param-reassign
        delete row[initialFilterOn.column];
        return row;
      }
    });
    filteredColumns = columns.filter((row) => row.name !== initialFilterOn.column);
  }

  const getFormatter = (type: string, name: string): any => {
    switch (type) {
      case 'boolean':
        return (value: boolean) => (<Checkbox checked={value} />);
      case 'date':
        return (value?: Date) => (value ? format(value, 'd MMM yy') : '');
      case 'datetime':
        return (value?: Date) => (value ? format(value, 'd MMM yy HH:mm:ss') : '');
      default:
        // TODO: Not ideal, but...
        // ... adds a link to the table when viewing, for example, form instances
        if (name.toLowerCase().match(/link/)) {
          return (value: string) => {
            if (value.startsWith('https://')) {
              return <a href={value} rel="noopener noreferrer" target="_blank">{value}</a>;
            }
            return undefined;
          };
        }
        return undefined;
    }
  };

  const getFilterType = (type: string): FilterType => {
    switch (type) {
      case 'boolean': return 'checkbox';
      // case 'datetime': return 'custom';
      // case 'text': return 'textField';
      default:
        return 'multiselect';
    }
  };

  const getSearchable = (type: string) => {
    switch (type) {
      case 'boolean':
      case 'date':
      case 'datetime':
      case 'number':
        return false;
      default:
        return true;
    }
  };
  const cols = filteredColumns || columns;
  const muiColumns: MUIDataTableColumnDef[] = cols
    .map((column) => ({
      name: column.name,
      label: column.name,
      options: {
        customBodyRender: getFormatter(column.type, column.name),
        filter: true,
        filterType: getFilterType(column.type),
        searchable: getSearchable(column.type),
        sort: true,
      },
    }));

  return (
    <MUIDataTable
      title={reportName}
      data={filteredData || data}
      columns={muiColumns}
      options={{
        elevation: 0,
        selectableRows: 'none',
      }}
    />
  );
}
