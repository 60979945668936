/* eslint-disable react/jsx-props-no-spreading */
import { MonthView } from '@devexpress/dx-react-scheduler-material-ui';
import { Button, DialogActions, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { formatISO } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useCreateTask } from '../../../apollo-hooks';
import { IAssignee } from '../../../formly/components/Assignees';
import FormlyForm from '../../../formly/FormlyForm';
import { IModel } from '../../../formly/IModel';
import getStaticTemplate from '../../../formly/staticTemplates';
import { AssigneeType, CreateTaskInputType, CreateTaskMutationVariables, TaskListItemFragment } from '../../../tillr-graphql';
import { formatAsFriendlyDate, generateUuid } from '../../../utils';
import ModuleContext from '../../Modules/ModuleContext';
import SiteContext from '../../Sites/SiteContext';
import UserProfileContext from '../../../UserProfileContext';

interface IProps {
  onTaskCreated?: (task: TaskListItemFragment) => void;
}

export default function TimeTableCellWithAddTask(props: IProps & MonthView.TimeTableCellProps) {
  const { onTaskCreated, ...timeTableCellProps } = props;
  const { startDate } = timeTableCellProps;
  const userProfile = useContext(UserProfileContext);

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createTask, { loading, data, reset }] = useCreateTask();
  const [taskId, setTaskId] = useState(generateUuid());

  const [open, setOpen] = React.useState(false);

  if (open && data?.createTask) {
    if (onTaskCreated) {
      onTaskCreated(data.createTask);
    }
    setOpen(false);
    reset();
    setTaskId(generateUuid());
  }

  const handleSubmit = (model: IModel) => {
    const task: CreateTaskInputType = {
      id: taskId,
      name: model.name || '',
      description: model.description || '',
      dueDate: model.dueDate,
      assignees: model.assignees && model.assignees
        .map((x: IAssignee) => ({
          assigneeId: x.id,
          assigneeType: x.type === 'User' ? AssigneeType.User : AssigneeType.Team,
        })),
    };

    const variables: CreateTaskMutationVariables = { siteId, module, task };
    createTask({ variables });
  };

  const handleClickOpen = () => {
    if (!userProfile?.hasAnyPermission(['Tasks.Edit'])) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const config = getStaticTemplate('create-task-from-calendar');
  const model = {
    dueDate: formatISO(startDate),
    dueDateFriendly: formatAsFriendlyDate(startDate),
  };

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const canCreateTaskForDate = startDate > yesterday;

  return (
    <>
      <MonthView.TimeTableCell {...timeTableCellProps} onDoubleClick={handleClickOpen} />
      {canCreateTaskForDate ? (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create new task</DialogTitle>
          <DialogContent>
            <FormlyForm
              formId={taskId}
              mode="create"
              config={config}
              model={model}
              onSubmit={handleSubmit}
              onCancel={handleClose}
              submitting={loading}
              modal
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cannot create task in the past</DialogTitle>
          <DialogContent>
            <Typography variant="body1" display="block">
              Please select today or a future date.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
