/* eslint-disable react/jsx-props-no-spreading */
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { createStyles, darken, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import QuickViewModal from '../QuickViewModal';

const useStyles = makeStyles((theme: Theme) => createStyles({
  appointment: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },
}));

interface IProps {
  taskId: string;
}

export default function AppointmentWithQuickView(props: IProps & Appointments.AppointmentProps) {
  const { taskId, ...appointmentProps } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Appointments.Appointment
        {...appointmentProps}
        className={classes.appointment}
        onClick={handleClickOpen}
      />
      <QuickViewModal
        taskId={taskId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}
