import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import AddSite from './AddSite';
import EditSiteName from './EditSiteName';

interface IProps {
  siteId: string;
  name: string;
}

export default function SiteOptions(props: IProps) {
  const { siteId, name } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="action-button"
        aria-label="options"
        size="small"
        aria-controls="site-options"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`site-options-${siteId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <AddSite onCloseMenu={handleClose} parent={siteId} />
        </MenuItem>
        <MenuItem>
          <EditSiteName onCloseMenu={handleClose} siteId={siteId} siteName={name} />
        </MenuItem>
      </Menu>
    </>
  );
}
