import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import { IData } from '../../IFormlyConfig';

interface IProps {
  data: IData | undefined;
  inline?: boolean;
}

export default function ElementInfo(props: IProps) {
  const { data, inline = false } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (data && data.info) {
    return (
      <>
        { inline ? (
          <IconButton
            onClick={handleOpen}
          >
            {data.info.type === 'help' ? (
              <HelpIcon />
            ) : (
              <InfoIcon />
            )}
          </IconButton>
        ) : (
          <InputAdornment position="end">
            <IconButton
              onClick={handleOpen}
            >
              {data.info.type === 'help' ? (
                <HelpIcon />
              ) : (
                <InfoIcon />
              )}
            </IconButton>
          </InputAdornment>
        )}
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="element-info-title"
        >
          <DialogTitle id="element-info-title">
            {data.info.title}
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              display="block"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: String(data.info.body!) }}
            />
            <Grid
              alignItems="center"
              container
              direction="row"
              justify="flex-end"
              spacing={3}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return <></>;
}
