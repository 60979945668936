import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface IProps {
  hasPreviousPage: boolean;
  onFetchPrevious: () => void;
  hasNextPage: boolean;
  onFetchNext: () => void;
}

export default function PreviousNextControl(props: IProps) {
  const {
    hasPreviousPage, onFetchPrevious, hasNextPage, onFetchNext,
  } = props;

  return (
    <>
      <IconButton
        aria-label="delete"
        disabled={!hasPreviousPage}
        onClick={onFetchPrevious}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        disabled={!hasNextPage}
        onClick={onFetchNext}
      >
        <ChevronRightIcon />
      </IconButton>
    </>
  );
}
