import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  help: {
    color: theme.palette.grey[700],
    fontSize: '1rem',
    margin: theme.spacing(0.6, 0.6, 0, 0.6),
  },
}));

interface IProps {
  value: string;
}

export default function HelpText(props: IProps) {
  const classes = useStyles();
  const { value } = props;
  return <div className={classes.help}>{value}</div>;
}
