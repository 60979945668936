import clsx from 'clsx';
import React, { useEffect } from 'react';
import { schemePaired } from 'd3-scale-chromatic';
import { Avatar, Checkbox, FormControl, FormControlLabel, TableRow, TableCell, Typography } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HelpText from './shared/HelpText';
import { IComponentProps } from '../IComponentProps';
import { getRuleMessage, FieldValidationMessage } from './shared/Validation';
import { IFormlyField } from '../IFormlyConfig';
import { formatAsFriendlyDate } from '../../utils';

export default function DashboardDocumentsCheckbox(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    trigger,
  } = props;

  useEffect(() => {
    register({ name: field.key }, { required: { value: field.templateOptions?.required, message: getRuleMessage('required') } });
    setValue(field.key, !!modelValue);
    if (modelValue) {
      trigger(field.key!);
    }
  }, [register, field, modelValue, setValue, trigger]);

  const getKeyColor = (doc: IFormlyField) => {
    if (!modelValue) {
      return '#cccccc';
    }
    if (doc?.data?.completed?.length) {
      return schemePaired[1];
    }
    return schemePaired[5];
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.checked);
      setValue(field.key!, !!event.currentTarget.checked);
      trigger(field.key!);
    }
  };

  const handleClick = () => {

  };

  return (
    <TableRow
      key={field.key}
      className={
        clsx(
          field?.data?.completed?.length ? 'table__row--strong' : '',
          !modelValue ? 'table__row--disabled' : '',
          'table--fixed',
        )
      }
      onClick={() => handleClick()}
      hover
    >
      <TableCell
        component="th"
        scope="row"
      >
        <div className="like-list-item-avatar">
          {/* TODO: Pass avatar icon as a prop */}
          <Avatar
            style={{ backgroundColor: getKeyColor(field) }}
          >
            <ListAltIcon />
          </Avatar>
          <div className="like-list-item-avatar__text">
            <Typography variant="body1" display="block">{field?.templateOptions?.label}</Typography>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {modelValue ? (
          <>
            {field?.data?.completed?.length ? (
              <span>
                {field?.data?.completed[0]['Created by']}
              </span>
            ) : (
              <span>
                --
              </span>
            )}
          </>
        ) : (
          <span />
        )}
      </TableCell>
      <TableCell>
        {modelValue ? (
          <>
            {field?.data?.completed?.length ? (
              <span>
                {formatAsFriendlyDate(new Date(field?.data?.completed[0]['Created date']))}
              </span>
            ) : (
              <span>
                --
              </span>
            )}
          </>
        ) : (
          <span />
        )}
      </TableCell>
      {!readonly && (
        <TableCell
          align="right"
        >
          <FormControl
            component="fieldset"
            disabled={field.templateOptions?.required}
            error={!!errors[field.key!]}
          >
            <FormControlLabel
              checked={!!modelValue}
              control={(
                <Checkbox
                  onChange={handleChange}
                  id={field.key}
                  required={!readonly && field.templateOptions?.required}
                />
              )}
              label=""
            />
            {errors[field.key!] && <FieldValidationMessage className="non-input" message={errors[field.key!].message} />}
            {field.data?.help && <HelpText value={field.data?.help} />}
          </FormControl>
        </TableCell>
      )}
    </TableRow>
  );
}
