import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useGetDownloadAttachmentUrlTemplate } from '../../apollo-hooks';
import { ParentType } from '../../tillr-graphql';
import ActivityIndicator from '../ActivityIndicator';
import ErrorDisplay from '../ErrorDisplay';
import AddAttachmentsControl from './AddAttachmentsControl';
import DeleteAttachmentControl from './DeleteAttachmentControl';
import DownloadAttachmentControl from './DownloadAttachmentControl';
import EditAttachmentControl from './EditAttachmentControl';
import { IAttachment } from './IAttachment';

const useStyles = makeStyles(() => createStyles({
  likeTextField: {
    fontSize: '14px',
    margin: '0',
    padding: '6px 0 7px',
  },
}));

interface IProps {
  parentId: string;
  parentType: ParentType;
  attachments?: IAttachment[] | null;
  enableCreate?: boolean;
  onAttachmentsUpdated?: (attachments: IAttachment[]) => void;
}

export default function ManageAttachmentsControl(props: IProps) {
  const classes = useStyles();
  const {
    parentId, parentType, attachments: initialAttachments,
    enableCreate, onAttachmentsUpdated,
  } = props;

  const { loading, error, data } = useGetDownloadAttachmentUrlTemplate();
  const [attachments, setAttachments] = useState(initialAttachments || []);

  if (initialAttachments && initialAttachments.length !== attachments.length) {
    setAttachments(initialAttachments);
  }

  const handleUploaded = (uploadedAttachments: IAttachment[]) => {
    const nextAttachments = attachments.concat(uploadedAttachments);
    setAttachments(nextAttachments);
    if (onAttachmentsUpdated) {
      onAttachmentsUpdated(nextAttachments);
    }
  };

  const handleAttachmentUpdated = (attachment: IAttachment) => {
    const nextAttachments = attachments.slice();
    const changedIndex = nextAttachments.findIndex((x) => x.id === attachment.id);
    if (changedIndex >= 0) {
      nextAttachments[changedIndex] = attachment;
      setAttachments(nextAttachments);
      if (onAttachmentsUpdated) {
        onAttachmentsUpdated(nextAttachments);
      }
    }
  };

  const handleAttachmentDeleted = ({ id }: IAttachment) => {
    const nextAttachments = attachments.filter((x) => x.id !== id);
    setAttachments(nextAttachments);
    if (onAttachmentsUpdated) {
      onAttachmentsUpdated(nextAttachments);
    }
  };

  return (
    <div>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.downloadAttachmentUrlTemplate && attachments && attachments.map((x) => (
        <div key={x.id}>
          {enableCreate ? (
            <>
              <EditAttachmentControl attachment={x} onUpdate={handleAttachmentUpdated} />
              <DeleteAttachmentControl
                parentId={parentId}
                parentType={parentType}
                attachment={x}
                onDelete={handleAttachmentDeleted}
              />
            </>
          )
            : (
              <p className={classes.likeTextField}>
                <strong>{x.name}</strong>
              </p>
            )}
          <DownloadAttachmentControl
            downloadAttachmentUrlTemplate={data.downloadAttachmentUrlTemplate!}
            attachment={x}
          />
        </div>
      ))}
      {data?.downloadAttachmentUrlTemplate && attachments && !attachments.length && (
        <p>No attachments.</p>
      )}
      {enableCreate && (
        // Use a key to reset this control when attachments array changes
        <div key={attachments.length}>
          <AddAttachmentsControl
            parentId={parentId}
            parentType={parentType}
            onUploaded={handleUploaded}
          />
        </div>
      )}
    </div>
  );
}
