import { Avatar, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { getAvatarName, avatarNameCache } from '../../utils';
import { miscColors } from '../../themes/default';

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '1em',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: '1.1em',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      fontSize: '1.25em',
    },
  },
}));
interface IUser {
  name: string;
  id?: string;
}

export default function UserAvatar(props: IUser) {
  const classes = useStyles();
  const { name, id } = props;
  const key = id || name;
  const colorKey = Object.keys(avatarNameCache).length % miscColors.length;

  if (!avatarNameCache[key]) {
    avatarNameCache[key] = miscColors[colorKey];
  }

  return (
    <Tooltip title={name}>
      <Avatar className={classes.avatar} style={avatarNameCache[id || name]}>
        {getAvatarName(name)}
      </Avatar>
    </Tooltip>
  );
}

/*
          <UserAvatar name="Danny Callaghan" id="123" />
          <UserAvatar name="John Doe" id="456" />
          <UserAvatar name="Danny Callaghan" id="123" />
          <UserAvatar name="Alan Shearer" id="789" />
          <UserAvatar name="Les Ferdinand" id="101112" />
          <UserAvatar name="Rob Lee" id="131415" />
          <UserAvatar name="Gary Speed" id="161718" />
          <UserAvatar name="Rob Lee" id="131415" />
*/
