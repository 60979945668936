import { ApolloError } from '@apollo/client';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Maybe, PendingInvitationFragment } from '../../../tillr-graphql';
import { formatAsFriendlyDate } from '../../../utils';
import ErrorDisplay from '../../ErrorDisplay';
import ProgressBar from '../../Shared/ProgressBar';
import LoadingUserRow from './LoadingUserRow';
import SitesSummary from './SitesSummary';

const useStyles = makeStyles((theme: Theme) => createStyles({
  info: {
    color: '#666',
    marginBottom: theme.spacing(1),
  },
}));

interface IProps {
  data?: Maybe<PendingInvitationFragment[]>;
  loading: boolean;
  error?: ApolloError;
}

export default function PendingUsers(props: IProps) {
  const classes = useStyles();
  const { data, loading, error } = props;

  function Row(rowProps: { row: PendingInvitationFragment }) {
    const { row } = rowProps;
    return (
      <>
        <TableRow
          hover
        >
          <TableCell>
            <Typography variant="body1">
              {row.email}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              {formatAsFriendlyDate(new Date(row.createdDateTime))}
            </Typography>
          </TableCell>
          <TableCell>
            <SitesSummary sites={row.sites} />
          </TableCell>
        </TableRow>
      </>
    );
  }

  function Head() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="col--35">
            <Typography variant="body1">
              Email
            </Typography>
          </TableCell>
          <TableCell className="col--30">
            <Typography variant="body1">
              Invited
            </Typography>
          </TableCell>
          <TableCell className="col--35">
            <Typography variant="body1">
              Sites
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      {error && <ErrorDisplay error={error} />}

      {loading && (
        <>
          <ProgressBar />
          <Typography variant="body1">
            &nbsp;
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <Head />
              <TableBody>
                <LoadingUserRow cells={3} />
                <LoadingUserRow cells={3} />
                <LoadingUserRow cells={3} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {data && (
        <>
          <Typography variant="body1" className={classes.info}>
            Total pending users:
            {` ${data.length}`}
          </Typography>
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <Head />
                <TableBody>
                  {data.map((row) => (
                    <Row key={row.email} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
}
