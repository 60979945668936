import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { IComponentProps } from '../IComponentProps';
import withComponentPropsMemo from '../withComponentPropsMemo';

function Heading(props: IComponentProps) {
  const { field } = props;
  return (
    <>
      { field.className?.match(/clear/) && <hr className="tillr-form-grid-element tillr-form-grid-element--break" /> }
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {field?.defaultValue}
        </Typography>
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Heading);
