import React from 'react';
import './MagicForm.scss';

interface IProps {
  model: any;
  onSubmit: (model: any) => void;
  submitting?: boolean;
}

export default class MagicForm extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);

    const { model } = props;
    this.state = model;
  }

  private addElement = (key: string, model: any) => {
    this.setState((prevState: any) => {
      const copyOfArray = prevState[key].slice();
      const copyOfModel = typeof (model) === 'object' ? { ...model } : '';
      copyOfArray.push(copyOfModel);
      return ({ ...prevState, [key]: copyOfArray });
    });
  };

  private removeElement = (key: string, index: number) => {
    this.setState((prevState: any) => {
      const copyOfArray = (prevState[key] as Array<any>).filter((_, i) => i !== index);
      return ({ ...prevState, [key]: copyOfArray });
    });
  };

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name: key, value } = event.target as HTMLInputElement;
    this.setState((prevState: any) => {
      const indexedKey = key.split(/[[\].]/);
      if (indexedKey.length === 3) {
        const arrayKey = indexedKey[0];
        const index = Number(indexedKey[1]);
        const array = prevState[arrayKey].slice();
        array[index] = value;
        return ({ ...prevState, [arrayKey]: array });
      }
      if (indexedKey.length === 4) {
        const arrayKey = indexedKey[0];
        const index = Number(indexedKey[1]);
        const itemKey = indexedKey[3];
        const array = prevState[arrayKey].slice();
        array[index][itemKey] = value;
        return ({ ...prevState, [arrayKey]: array });
      }
      return ({ ...prevState, [key]: value });
    });
  };

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  private renderFormGroup(key: string, model: any): JSX.Element {
    if (model != null && typeof (model) === 'object') {
      const keys = Object.keys(model);
      const keyPrefix = key ? `${key}.` : '';
      return (
        <>
          {keys.map((k) => {
            if (Array.isArray(model[k])) {
              const arrayModel = model[k] as Array<any>;
              return (
                <React.Fragment key={k}>
                  {arrayModel.map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {this.renderFormGroup(`${keyPrefix}${k}[${i}]`, x)}
                      <div className="form-group">
                        <button type="button" onClick={() => this.removeElement(`${keyPrefix}${k}`, i)}>remove</button>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="form-group">
                    <button type="button" onClick={() => this.addElement(`${keyPrefix}${k}`, arrayModel[arrayModel.length - 1])}>add</button>
                  </div>
                </React.Fragment>
              );
            }
            return this.renderFormGroup(`${keyPrefix}${k}`, model[k]);
          })}
        </>
      );
    }
    return (
      <div key={key} className="form-group">
        <label htmlFor={key}>{key}</label>
        <input id={key} name={key} className="form-control" value={model != null ? model : ''} onChange={this.handleChange} />
      </div>
    );
  }

  public render() {
    const { submitting } = this.props;
    const model = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderFormGroup('', model)}
        <button type="submit" disabled={submitting}>Submit</button>
        {submitting && <span>Submitting...</span>}
      </form>
    );
  }
}
