import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { addDays } from 'date-fns';
import { IComponentProps } from '../IComponentProps';
import { IFormlyField, IFormlyConfig } from '../IFormlyConfig';
// eslint-disable-next-line import/no-cycle
import FormlyForm from '../FormlyForm';
import CancelButton from '../../components/Shared/CancelButton';
import FormsButtons from '../../components/Shared/FormsButtons';
import ProgressButton from '../../components/Shared/ProgressButton';
import getStaticTemplate from '../staticTemplates';
import { formatAsFriendlyDate } from '../../utils';
// eslint-disable-next-line import/no-cycle
import getFieldTypeComponent from '../getFieldTypeComponent';
import { IAssignee } from './Assignees';
import { IModel } from '../IModel';
// eslint-disable-next-line import/extensions
import { IAppTheme } from '../../themes/useGetTheme';
import SiteContext from '../../components/Sites/SiteContext';
import { useUiDataProvider } from '../../UiDataProvider';

const useStyles = makeStyles((theme: IAppTheme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: theme.automatedTasks?.colorDark,
    '& .MuiCardHeader-title': {
      fontSize: '1.35rem',
      fontWeight: 400,
      lineHeight: 1.334,
    },
    '& .MuiCardContent-root': {
      backgroundColor: theme.automatedTasks?.colorLight,
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    '& .MuiCardHeader-action': {
      alignSelf: 'auto',
      marginTop: 0,
      marginRight: 0,
    },
  },
  taskIcon: {
    fontSize: '3rem',
    color: theme.automatedTasks?.defaultColor,
  },
  taskIconSmall: {
    color: '#fff',
  },
  taskAvatar: {
    backgroundColor: theme.automatedTasks?.defaultColor,
  },
  taskAction: {
    backgroundColor: theme.automatedTasks?.defaultColor,
    borderColor: theme.automatedTasks?.defaultColor,
    color: '#fff',
  },
  taskButton: {
    color: theme.automatedTasks?.defaultColor,
  },
  spacing: {
    margin: theme.spacing(4, 0, 2, 0),
    color: theme.automatedTasks?.defaultColor,
  },
  noTopSpacing: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  readonly: {
    '& .MuiCardContent-root': {
      backgroundColor: theme.automatedTasks?.colorLight,
    },
  },
}));

interface ITasks {
  question: string;
  include: string[];
  tasks?: IModel[];
}

export default function AutomatedTasks(props: IComponentProps) {
  const classes = useStyles();
  const {
    field,
    fields,
    model,
    formId,
    readonly,
    modelValue,
    onValueUpdate,
    trackAutomatedTasks,
  } = props;
  const {
    register: taskFormRegister,
    errors: taskFormErrors,
    setValue: taskFormSetValue,
    clearErrors: taskFormClearErrors,
    setError: taskFormSetError,
    getValues: taskFormGetValues,
    formState: taskFormFormState,
    reset,
    ...useFormProps
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { uiData } = useUiDataProvider();
  const siteContext = useContext(SiteContext)!;

  const [questionField, setQuestionField] = useState<IFormlyConfig>({ fields: [] });
  const [additionalFields, setAdditionalFields] = useState<IFormlyConfig>({ fields: [] });
  const [open, setOpen] = useState(false);
  const initialModel = {};
  const els = getStaticTemplate('create-automated-task');
  const [taskModel, setTaskModel] = useState<IModel>(initialModel);
  const [taskList, setTaskList] = useState<ITasks | null>(null);
  const [mode, setMode] = useState<'create' | 'update'>('create');
  const [currentTask, setCurrentTask] = useState<number | null>(null);

  function initialiseModel(initModel: any, formFields: IFormlyField[]) {
    formFields.forEach((x) => {
      if (x.key && x.defaultValue != null) {
        // eslint-disable-next-line no-param-reassign
        initModel[x.key] = x.defaultValue;
      }
      if (x.fieldGroup) {
        initialiseModel(initModel, x.fieldGroup);
      }
    });
  }

  initialiseModel(initialModel, els.fields);

  useEffect(() => {
    function getRelevantFields(elements: IFormlyField[]) {
      let relevantFormFields: IFormlyField[] = [];
      const iterrateOver = (elms: IFormlyField[]) => {
        elms.forEach((element) => {
          if (element?.data?.format === 'element'
            && field?.data?.tasks?.include?.includes(element?.key as string)
            && element.type !== 'automatedtasks') {
            if (element?.key as string === field?.data?.tasks?.question) {
              setQuestionField({ fields: [element] });
            } else {
              relevantFormFields = relevantFormFields.concat([element]);
            }
          } else if (element.fieldGroup) {
            iterrateOver(element.fieldGroup);
          }
        });
      };
      elements.forEach((element: IFormlyField) => {
        if (element.fieldGroup) {
          iterrateOver(element.fieldGroup);
        }
      });
      return {
        fields: relevantFormFields,
      };
    }
    if (fields && field?.data?.tasks?.include?.length) {
      setAdditionalFields(getRelevantFields(fields));
    }
    if (modelValue && modelValue.length) {
      const savedTaskData = JSON.parse(modelValue);
      setTaskList(savedTaskData);
      if (savedTaskData?.tasks?.length) {
        // eslint-disable-next-line array-callback-return, consistent-return
        const unCreatedTasks = savedTaskData.tasks.filter((task: IModel) => {
          if (typeof task.id === 'undefined') {
            return task;
          }
        });
        trackAutomatedTasks(field?.key, unCreatedTasks);
      }
    }
  }, [field, fields, model, modelValue, trackAutomatedTasks]);

  useEffect(() => {
    if (questionField) {
      const defaults = uiData?.automatedTaskDefaults;
      if (defaults) {
        setTaskModel((prevTaskModel) => {
          // TODO: use immer
          const nextTaskModel = { ...prevTaskModel };
          nextTaskModel.name = questionField.fields[0]?.templateOptions?.label?.replaceAll('?', '') ?? '';
          nextTaskModel.assignees = defaults.assigneesBySiteId?.[siteContext.siteId];

          if (defaults.taskDueInDays !== undefined) {
            const dueDate = addDays(new Date(new Date().toDateString()), defaults.taskDueInDays);
            nextTaskModel.dueDate = dueDate.toDateString();
          }
          return nextTaskModel;
        });
      }
    }
  }, [questionField, uiData, siteContext]);

  const taskFormOnValueUpdate = (fieldKey: string, value: any) => {
    setTaskModel((prevState: any) => ({ ...prevState, [fieldKey]: value }));
  };

  function renderTaskForm(): (JSX.Element | null)[] {
    return els.fields.map((el) => {
      const componentProps = {
        field: el,
        modelValue: taskModel[el.key as string],
        onValueUpdate: taskFormOnValueUpdate,
        readonly: false,
        register: taskFormRegister,
        errors: taskFormErrors,
        setValue: taskFormSetValue,
        clearErrors: taskFormClearErrors,
        setError: taskFormSetError,
        getValues: taskFormGetValues,
        formState: taskFormFormState,
      };

      const fieldComponent = getFieldTypeComponent(el.type as string);

      return React.createElement(fieldComponent, {
        key: el.key as string,
        ...componentProps,
        ...useFormProps,
      });
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    setCurrentTask(null);
    setMode('create');
    reset(initialModel);
  };

  const handleCancel = () => {
    handleClose(null, '');
  };

  function updateModelAndUI(data: ITasks) {
    onValueUpdate(field?.key as string, JSON.stringify(data));
    setTaskList(data);
    trackAutomatedTasks(field?.key, data.tasks);
  }

  const handleCreateTask = () => {
    if (field.data?.tasks) {
      if (mode === 'update' && typeof currentTask === 'number') {
        const data = JSON.parse(modelValue);
        data.tasks[data.tasks.findIndex((_el: IModel, i: number) => i === currentTask)] = taskModel;
        updateModelAndUI(data);
      } else {
        const data: ITasks = field.data.tasks;
        data.tasks = taskList ? taskList.tasks : [];
        // @ts-ignore
        data.tasks = data.tasks.concat([taskModel]);
        updateModelAndUI(data);
      }
    }
    setOpen(false);
    setTaskModel(initialModel);
    reset(initialModel);
  };

  const handleEditTask = (index: number) => {
    if (taskList && taskList.tasks && taskList.tasks[index]) {
      setTaskModel(taskList.tasks[index]);
      setMode('update');
      setCurrentTask(index);
      setOpen(true);
    }
  };

  const handleDeleteTask = (index: number) => {
    if (taskList && taskList.tasks && taskList.tasks[index]) {
      const newTasks = taskList.tasks.filter((task, i) => i !== index && task);
      const newTaskList = {
        ...taskList,
        tasks: newTasks,
      };
      updateModelAndUI(newTaskList);
    }
  };

  function assigneeList(assignees: IAssignee[]) {
    const list = assignees.map((assignee: IAssignee) => assignee.name);
    return list.join(', ');
  }

  return (
    <Grid
      item
      xs={12}
      sm={field.className?.match(/col-xs-6/) ? 6 : 12}
      className={field.className?.match(/pull-right/) ? 'tillr-form-grid-element tillr-form-grid-element--align-right' : 'tillr-form-grid-element'}
    >
      <Card className={classes.card}>
        <CardHeader
          avatar={(
            <AssignmentTurnedInIcon className={classes.taskIcon} />
          )}
          title={readonly ? 'Assigned tasks' : 'Add tasks to this response'}
          subheader={readonly ? 'Any new tasks will be assigned if this form is edited. Existing tasks will not be reassigned.'
            : 'New tasks will be assigned when this form is saved. Existing tasks will not be reassigned.'}
          action={!readonly && (
            <Tooltip title="Create and assign a task">
              <Button
                variant="contained"
                aria-label="create task"
                className={classes.taskAction}
                onClick={handleClickOpen}
              >
                Create new task
              </Button>
            </Tooltip>
          )}
        />
        {taskList && taskList.tasks && taskList.tasks.length > 0 && (
        <CardContent>
          <List>
            {taskList.tasks.map((task, i) => (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                <ListItemAvatar>
                  <Avatar className={classes.taskAvatar}>
                    <AssignmentTurnedInIcon className={classes.taskIconSmall} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={task.name}
                  secondary={`${formatAsFriendlyDate(new Date(task.dueDate))} - ${assigneeList(task.assignees)}`}
                />
                {!readonly && !task.id && (
                <ListItemSecondaryAction>
                  <IconButton
                    className={classes.taskButton}
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEditTask(i)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    className={classes.taskButton}
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteTask(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </CardContent>
        )}
      </Card>
      <input
        id={field.key}
        name={field.key}
        value={modelValue || ''}
        type="hidden"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Create task</DialogTitle>
        {open && (
          <DialogContent>
            <DialogContentText className={`${classes.spacing} ${classes.noTopSpacing}`}>
              Create a task as a result of your response to the following question:
            </DialogContentText>
            <div className={classes.readonly}>
              <FormlyForm
                mode="readonly"
                config={questionField}
                model={model}
              />
            </div>
            {additionalFields.fields.length > 0 && (
            <>
              <DialogContentText className={classes.spacing}>
                The following information will be included in the task:
              </DialogContentText>
              <div className={classes.readonly}>
                <FormlyForm
                  formId={formId}
                  mode="readonly"
                  config={additionalFields}
                  model={model}
                />
              </div>
            </>
            )}
            <DialogContentText className={classes.spacing}>
              Complete the information below to save this task.
               Please note that the task will not be created
               and assigned until the form is saved.
            </DialogContentText>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                {renderTaskForm()}
                <FormsButtons>
                  <CancelButton
                    label="Cancel"
                    onClick={handleCancel}
                  />
                  <ProgressButton
                    label="Save"
                    isValid={taskFormFormState.isValid}
                    onClick={handleCreateTask}
                  />
                </FormsButtons>
              </Grid>
            </form>
          </DialogContent>
        )}
      </Dialog>
    </Grid>
  );
}
