import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import ModuleLink from '../Modules/ModuleLink';
import QuickView from './QuickView';
import UserProfileContext from '../../UserProfileContext';

const useStyles = makeStyles(() => createStyles({
  dialog: {
    minWidth: '300px',
  },
}));

interface IProps {
  taskId: string;
  open: boolean;
  handleClose: () => void;
}

export default function QuickViewModal(props: IProps) {
  const { taskId, open, handleClose } = props;
  const classes = useStyles();
  const userProfile = useContext(UserProfileContext);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">Task details</DialogTitle>
      <DialogContent className={classes.dialog}>
        <QuickView taskId={taskId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {userProfile?.hasAnyPermission(['Tasks.Edit']) && (
        <ModuleLink to={`/tasks/${taskId}/edit`} className="no-underline">
          <Button onClick={handleClose} color="primary">
            Edit
          </Button>
        </ModuleLink>
        )}
        <ModuleLink to={`/tasks/${taskId}`} className="no-underline">
          <Button onClick={handleClose} color="primary">
            View
          </Button>
        </ModuleLink>
      </DialogActions>
    </Dialog>
  );
}
