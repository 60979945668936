import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const makeAttachmentStyles = makeStyles((theme: Theme) => createStyles({
  attachmentLinks: {
    color: 'black',
    minWidth: 'auto',
    padding: 0,
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    '&::before': {
      content: '" - "',
    },
    '& span': {
      cursor: 'pointer',
      padding: '0 2px',
      textDecoration: 'underline',
    },
  },
}));

export default makeAttachmentStyles;
