import { Avatar, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FolderIcon from '@material-ui/icons/Folder';
import React, { useContext, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useGetLibraryFiles, useGetLibraryFolder } from '../../apollo-hooks';
import { LibraryAncestorFolderType, LibraryFileSearchResultFragment, ParentType } from '../../tillr-graphql';
import UserProfileContext from '../../UserProfileContext';
import { formatAsFriendlyDate } from '../../utils';
import ErrorDisplay from '../ErrorDisplay';
import ActionButtons from '../Shared/ActionButtons';
import HorizontalLine from '../Shared/HorizontalLine';
import ProgressBar from '../Shared/ProgressBar';
import TagEditor from '../Tags/TagEditor';
import AddFiles from './AddFiles';
import AddFolder from './AddFolder';
import DeleteFileControl from './DeleteFileControl';
import DeleteFolderControl from './DeleteFolderControl';
import DownloadFile from './DownloadFile';
import LibraryFileIcon from './FileIcon';
import LibraryLocation from './LibraryLocation';
import LibrarySiteBreadcrumbs from './LibrarySiteBreadcrumbs';
import LoadingLibrary from './LoadingLibrary';
import SearchBox from './SearchBox';

const useStyles = makeStyles((theme: Theme) => createStyles({
  locationLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    padding: 0,
    textTransform: 'none',
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  location: {
    marginBottom: theme.spacing(1),
  },
  toolbar: {
    padding: theme.spacing(2),
  },
  fileName: {
    paddingLeft: theme.spacing(1.5),
  },
  tableCellText: {
    paddingTop: theme.spacing(1.5),
  },
  likeTextLink: {
    color: theme.palette.text.primary,
    minWidth: 'auto',
    padding: 0,
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    '& span': {
      cursor: 'pointer',
      padding: theme.spacing(0, 0.25),
      textDecoration: 'underline',
    },
  },
}));

export default function Library(props: RouteComponentProps<{ folderId: string }>) {
  const { match: { params: { folderId } } } = props;

  const classes = useStyles();
  const history = useHistory();
  const userProfile = useContext(UserProfileContext)!;
  const [query, setQuery] = useState('');
  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
  } = useGetLibraryFiles({ searchQuery: query });

  const {
    loading, error, data, refetch,
  } = useGetLibraryFolder({ folderId });

  const handleTagCreated = () => {
    // TODO: Handle this properly, refetching is a cheat but it works for now
    refetch();
  };
  const handleTagDeleted = () => {
    // TODO: Handle this properly, refetching is a cheat but it works for now
    refetch();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const route = `/library/${event.currentTarget.dataset.folderId}`;
    history.push(route);
  };

  const handleChange = (term: string) => {
    if (!term.length) return;
    setQuery(term);
  };

  const handleClear = () => {
    setQuery('');
  };

  const getLocationPath = (x: LibraryFileSearchResultFragment) => {
    const pathSeparator = ' / ';
    const ancestorsPath = x.ancestorFolders
      .map((y: LibraryAncestorFolderType) => y.name)
      .reverse().join(pathSeparator);
    return `${ancestorsPath}${(ancestorsPath.length ? pathSeparator : '')}${x.folder.name}`;
  };

  const handleSearchResultClick = (route: string) => {
    handleClear();
    history.push(route);
  };

  function ListViewHeader() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="col--40">Name</TableCell>
          <TableCell className="col--30">Tags</TableCell>
          <TableCell className="col--20">Date added</TableCell>
          <TableCell className="col--10" align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const userCanUpload = userProfile.hasAnyPermission(['Library.Upload']);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            Library
          </Typography>
          <LibrarySiteBreadcrumbs />
        </Grid>
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />
      {error && <ErrorDisplay error={error} />}
      {loading && <ProgressBar />}
      {data?.libraryFolder && (
        <>
          {userCanUpload && (
            <ActionButtons>
              <AddFolder
                folderId={data.libraryFolder.id}
                folderName={data.libraryFolder.name}
              />
              <AddFiles
                folderId={data.libraryFolder.id}
                folderName={data.libraryFolder.name}
              />
            </ActionButtons>
          )}
          <Paper className={classes.location}>
            <Toolbar
              className={classes.toolbar}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <LibraryLocation
                    query={query}
                    searchData={searchData}
                    data={data}
                    handleClear={handleClear}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SearchBox
                    query={query}
                    handleClear={handleClear}
                    handleChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </Paper>
          <Paper>
            {!!query.length && (
              <>
                <TableContainer>
                  <Table className="table--fixed">
                    <ListViewHeader />
                    <TableBody>
                      {searchError && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <ErrorDisplay error={searchError} />
                          </TableCell>
                        </TableRow>
                      )}
                      {searchLoading && (
                        <LoadingLibrary results />
                      )}
                      {searchData?.libraryFiles && searchData.libraryFiles.files.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Typography className={classes.tableCellText} variant="body1">
                              There are no results for the search term
                              {` "${query}".`}
                              <Button
                                className={classes.likeTextLink}
                                onClick={handleClear}
                              >
                                <span>Return to folder view.</span>
                              </Button>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {searchData?.libraryFiles && searchData.libraryFiles.files.map((x) => (
                        <TableRow hover key={x.id}>
                          <TableCell>
                            <div className="like-list-item-avatar">
                              <LibraryFileIcon file={x.name} />
                              <Typography className={classes.fileName} variant="body1">
                                {x.name}
                              </Typography>
                            </div>
                            <Typography className={classes.tableCellText} variant="body1" noWrap display="block">
                              File location:
                              <Button className={classes.locationLink} onClick={() => handleSearchResultClick(`/library/${x.folder.id}`)}>
                                {getLocationPath(x)}
                              </Button>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TagEditor
                              tags={x.tags}
                              parentType={ParentType.LibraryFile}
                              parentId={x.id}
                              onTagCreated={handleTagCreated}
                              onTagDeleted={handleTagDeleted}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.tableCellText} variant="body1" noWrap>
                              {formatAsFriendlyDate(new Date(x.createdDateTime))}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <DownloadFile data={data} file={x} />
                            {userCanUpload && (
                              <DeleteFileControl file={x} folderId={data.libraryFolder!.id} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {!query.length && (
              <>
                <TableContainer>
                  <Table className="table--fixed">
                    <ListViewHeader />
                    <TableBody>
                      {error && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <ErrorDisplay error={error} />
                          </TableCell>
                        </TableRow>
                      )}
                      {loading && (
                        <LoadingLibrary
                          results={false}
                          parent={data.libraryFolder.parentFolderId}
                        />
                      )}
                      {data.libraryFolder.parentFolderId && (
                        <TableRow
                          hover
                          data-folder-id={data.libraryFolder.parentFolderId}
                          onClick={handleClick}
                          className="user-clickable"
                        >
                          <TableCell colSpan={4}>
                            <div className="like-list-item-avatar">
                              <Avatar>
                                <ArrowUpwardIcon />
                              </Avatar>
                              <Box className="like-list-item-avatar__text">
                                <Typography variant="body1" display="block">
                                  [back up]
                                </Typography>
                              </Box>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                      {data.libraryFolder.folders
                        .slice().sort((a, b) => a.name.localeCompare(b.name))
                        .map((x) => (
                          <TableRow
                            hover
                            key={x.id}
                            data-folder-id={x.id}
                            onClick={handleClick}
                            className="user-clickable"
                          >
                            <TableCell>
                              <div className="like-list-item-avatar">
                                <Avatar>
                                  <FolderIcon />
                                </Avatar>
                                <Box className="like-list-item-avatar__text">
                                  <Typography variant="body1" display="block">
                                    {x.name}
                                  </Typography>
                                </Box>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableCellText} variant="body1" noWrap>
                                &mdash;
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableCellText} variant="body1" noWrap>
                                {formatAsFriendlyDate(new Date(x.createdDateTime))}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {userCanUpload && (
                                <DeleteFolderControl
                                  folder={x}
                                  parentFolderId={data.libraryFolder?.id}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      {data.libraryFolder.files.slice().sort((a, b) => a.name.localeCompare(b.name))
                        .map((x) => (
                          <TableRow hover key={x.id}>
                            <TableCell>
                              <div className="like-list-item-avatar">
                                <LibraryFileIcon file={x.name} />
                                <Typography className={classes.fileName} variant="body1">
                                  {x.name}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell>
                              <TagEditor
                                tags={x.tags}
                                parentType={ParentType.LibraryFile}
                                parentId={x.id}
                                onTagCreated={handleTagCreated}
                                onTagDeleted={handleTagDeleted}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableCellText} variant="body1" noWrap>
                                {formatAsFriendlyDate(new Date(x.createdDateTime))}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <DownloadFile data={data} file={x} />
                              {userCanUpload && (
                                <DeleteFileControl file={x} folderId={data.libraryFolder!.id} />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Paper>
        </>
      )}
    </div>
  );
}
