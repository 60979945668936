import { useState } from 'react';
import Dexie, { Table } from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import UserProfile from './UserProfile';

const version = 1;

interface IOfflineForm {
  formDataJson: string;
  id?: string;
  isSubmitted: boolean;
  name: string;
  templateId: string;
}

interface IOfflineFormPayload {
  createTasks?: boolean | null | undefined;
  form: IOfflineForm;
  module: string;
  siteId: number;
  activeFormTemplateId: null | undefined | string;
  template: string;
  templateName: string;
}

interface IOfflineFormStore {
  formDataJson: string;
  id?: string;
  isSubmitted: boolean;
  name: string;
  templateId: string;
  formTemplate: {
    activeFormTemplateId: null | undefined | string;
  };
  template: string;
  templateName: string;
  created: Date;
}

interface IOfflineStore {
  id: string | undefined;
  createTasks?: boolean | null | undefined;
  form: IOfflineFormStore;
  module: string;
  siteId: number;
}

interface IDBConfig {
  tenantId: string;
  userId: string;
}

export interface IOfflineFormItem {
  name: string;
  getDbInstanceyUser: {
    id: string;
    name: string;
  },
  createdDateTime: string;
  id: string;
  template: string;
}

class IDBDatabase extends Dexie {
  forms!: Table<IOfflineStore>;

  constructor(config: IDBConfig) {
    const dbName = `tillr-${config.tenantId}-${config.userId}`;
    super(dbName);
    this.version(version).stores({
      forms: 'id, createTasks, form, module, siteId', // Primary key and indexed props
    });
  }
}

let db: any = null;

function getDbInstance(userProfile: UserProfile) {
  const { tenantId } = userProfile;
  const userId = String(userProfile.getUserId());
  if (!db) {
    db = new IDBDatabase({
      tenantId,
      userId,
    });
  }
  return db;
}

export function useLocalStorage(userProfile: UserProfile) {
  // if (!userProfile) return null;
  const [savedOffline, setSavedOffline] = useState<string | false | undefined>(false);
  const dexieDb = getDbInstance(userProfile);

  async function createOfflineForm(payload: IOfflineFormPayload) {
    const obj = {
      ...payload,
      id: payload.form.id,
      form: {
        ...payload.form,
        formTemplate: {
          activeFormTemplateId: payload.activeFormTemplateId,
        },
        template: payload.template,
        templateName: payload.templateName,
        created: new Date(),
      },
    };

    await dexieDb.forms.add(obj);
    setSavedOffline(payload.form.id);
  }

  async function editOfflineForm(payload: IOfflineFormPayload) {
    const obj = {
      ...payload,
      id: payload.form.id,
      form: {
        ...payload.form,
        formTemplate: {
          activeFormTemplateId: payload.activeFormTemplateId,
        },
        template: payload.template,
        templateName: payload.templateName,
        created: new Date(),
      },
    };

    await dexieDb.forms.put(obj, obj.id);
    setSavedOffline(payload.form.id);
  }

  return { savedOffline, createOfflineForm, editOfflineForm };
}

export function useGetOfflineForm(userProfile: UserProfile, formId: string) {
  return useLiveQuery(
    () => {
      if (!userProfile) return null;
      const dexieDb = getDbInstance(userProfile);
      return dexieDb.forms.get(formId);
    },
    [formId],
  );
}

export function useGetOfflineFormsCount(userProfile: UserProfile | null) {
  return useLiveQuery(
    () => {
      if (!userProfile) return null;
      const dexieDb = getDbInstance(userProfile);
      return dexieDb.forms.count();
    },
    [],
  );
}

export function useGetOfflineForms(userProfile: UserProfile) {
  return useLiveQuery(
    async () => {
      if (!userProfile) return null;
      const dexieDb = getDbInstance(userProfile);
      const items = dexieDb.forms.toArray();
      const formattedForms: IOfflineFormItem[] = (await items).map((form: IOfflineStore) => ({
        name: form.form.name,
        getDbInstanceyUser: {
          id: userProfile.getUserId(),
          name: userProfile.name,
        },
        createdDateTime: form.form.created,
        id: form.id,
        template: form.form.templateName,
      }));
      return formattedForms;
    },
    [],
  );
}

export async function deleteOfflineForm(userProfile: UserProfile, formId: string) {
  const dexieDb = getDbInstance(userProfile);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteCount = await dexieDb.forms
    .where('id')
    .equals(formId)
    .delete();
}
