import React, { useState } from 'react';
import { Button, Chip, IconButton, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { miscColors } from '../../../../themes/default';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    margin: theme.spacing(1.5, 0),
  },
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0),
  },
  deleteIcon: {
    color: '#fff',
    padding: 0,
    margin: '0 5px 0 -6px',
    '&:hover': {
      color: '#fff',
    },
  },
  input: {
    width: 'auto',
    '& .MuiOutlinedInput-input': {
      padding: '7px 4px 8px 8px',
      maxHeight: '32px',
    },
  },
  button: {
    backgroundColor: '#333333',
    color: '#ffffff',
    margin: theme.spacing(0, 0, 0, 0.5),
    position: 'relative',
    top: '-2px',
  },
}));

interface IProps {
  roles: string[];
  edit?: boolean;
  handleRemove?: (index: number) => void;
  handleAdd?: (perm: string) => void;
}

export default function PermissionsAsChips(props: IProps) {
  const classes = useStyles();
  const {
    roles,
    edit,
    handleRemove,
    handleAdd,
  } = props;
  const [permission, setPermission] = useState<string>('');

  const getClassFromName = (role: string) => {
    if (role.toLowerCase().match(/files/)) {
      return miscColors[0];
    }
    if (role.toLowerCase().match(/forms/)) {
      return miscColors[1];
    }
    if (role.toLowerCase().match(/library/)) {
      return miscColors[2];
    }
    if (role.toLowerCase().match(/sites/)) {
      return miscColors[3];
    }
    if (role.toLowerCase().match(/tags/)) {
      return miscColors[4];
    }
    if (role.toLowerCase().match(/users/)) {
      return miscColors[5];
    }
    if (role.toLowerCase().match(/hq/)) {
      return {
        backgroundColor: '#ff0000',
        color: '#ffff00',
        border: 'solid 3px #000',
        fontWeight: 700,
      };
    }
    return miscColors[6];
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (handleRemove && event.currentTarget?.dataset?.index) {
      handleRemove(Number(event.currentTarget?.dataset?.index));
    }
  };

  const handleCreate = () => {
    if (handleAdd && permission.length) {
      handleAdd(permission);
      setPermission('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPermission(event.currentTarget.value);
  };

  return (
    <div className={classes.container}>
      {roles.map((role, index) => {
        if (role.length) {
          return (
            <Chip
              style={getClassFromName(role)}
              className={classes.root}
              key={role}
              label={role}
              color="primary"
              onDelete={edit ? handleDelete : undefined}
              deleteIcon={edit ? (
                <IconButton
                  data-index={index}
                  className={classes.deleteIcon}
                >
                  <CancelIcon />
                </IconButton>
              ) : undefined}
            />
          );
        }
        return '';
      })}
      {edit && (
      <>
        <TextField
          value={permission}
          placeholder="Permission"
          onChange={handleChange}
          className={classes.input}
          variant="outlined"
        />
        <Button
          size="small"
          onClick={handleCreate}
          variant="outlined"
          className={classes.button}
        >
          Add
        </Button>
      </>
      )}
    </div>
  );
}
