import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useContext, useState } from 'react';
import { useGetMySites } from '../../apollo-hooks';
import UserProfileContext from '../../UserProfileContext';
import ErrorDisplay from '../ErrorDisplay';
import SiteContext from './SiteContext';
import SitesDialog from './SitesDialog';

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  smallAvatar: {
    fontSize: '0.9rem',
  },
}));

export default function SiteControl() {
  const classes = useStyles();
  const userProfile = useContext(UserProfileContext);
  const siteContext = useContext(SiteContext);
  const [open, setOpen] = useState(false);
  const hasMultipleSites = Boolean(userProfile && userProfile.siteIds.length > 1);
  const { loading, error, data } = useGetMySites(hasMultipleSites);

  if (!hasMultipleSites || !siteContext) {
    return null;
  }

  const { siteId, setSiteId } = siteContext;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const site = data?.mySites ? data.mySites.find((x) => x.id === siteId) : null;

  const getInitials = (value: string) => {
    const initials = value.replace(/(\b[a-zA-Z])[a-zA-Z]* ?/gim, '$1').toUpperCase();
    return initials.replace(/[()-]/g, '').substring(0, 4);
  };

  const LoadingSites = () => (
    <Tooltip title="Switch site">
      <ListItem>
        <ListItemIcon>
          <Avatar>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </Avatar>
        </ListItemIcon>
        <ListItemText>
          Retrieving sites...
        </ListItemText>
      </ListItem>
    </Tooltip>
  );

  return (
    <>
      {loading && <LoadingSites />}
      {error && <ErrorDisplay error={error} />}
      {data?.mySites && site && (
        <>
          <Tooltip title="Switch site">
            <ListItem button>
              <ListItemIcon
                className="nav-icon"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Avatar
                  className={getInitials(site.name).length > 2 ? classes.smallAvatar : ''}
                >
                  {getInitials(site.name)}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                className="nav-link"
                aria-haspopup="true"
                onClick={handleClick}
              >
                {site.name}
              </ListItemText>
            </ListItem>
          </Tooltip>

          <SitesDialog
            open={open}
            sites={data.mySites}
            onChange={setSiteId}
            onClose={handleClose}
          />
        </>
      )}
    </>
  );
}
