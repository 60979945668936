import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect } from 'react-router';
import { useGetMyNotificationPreferences, useUpdateMyNotificationPreferences } from '../../apollo-hooks';
import { UpdateMyNotificationPreferencesMutationVariables, UserNotificationPreferenceInputType } from '../../tillr-graphql';
import ActivityIndicator from '../ActivityIndicator';
import ErrorDisplay from '../ErrorDisplay';
import MagicForm from '../MagicForm';

export default function EditNotificationPreferences() {
  const queryState = useGetMyNotificationPreferences();
  const [updateMyNotificationPreferences, mutationState] = useUpdateMyNotificationPreferences();

  if (mutationState.data?.updateMyNotificationPreferences) {
    return <Redirect to="/notification-preferences" />;
  }

  type FormModelType = { preferences: UserNotificationPreferenceInputType[] };

  const handleSubmit = (model: FormModelType) => {
    const { preferences } = model;
    const variables: UpdateMyNotificationPreferencesMutationVariables = { preferences };
    updateMyNotificationPreferences({ variables });
  };

  let model: FormModelType | null = null;
  if (queryState.data?.myNotificationPreferences && queryState.data?.notificationTypes) {
    model = {
      preferences: queryState.data.notificationTypes.map((x) => {
        const existingPref = queryState.data!.myNotificationPreferences!
          .find((y) => y.notificationType === x.value);
        return {
          notificationType: x.value,
          isEnabled: existingPref ? existingPref.isEnabled : x.defaultValue,
          customData: existingPref?.customData || '',
        };
      }),
    };
  }

  return (
    <div>
      <Typography variant="h2" noWrap>Notification preferences</Typography>
      {queryState.loading && <ActivityIndicator />}
      {queryState.error && <ErrorDisplay error={queryState.error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {model && (
        <MagicForm model={model} onSubmit={handleSubmit} submitting={mutationState.loading} />
      )}
    </div>
  );
}
