import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetMyFiles } from '../../apollo-hooks';
import { FileFilterBy, FileOrderBy, FilePaginationPropsType, PaginationOrder } from '../../tillr-graphql';
import ActivityIndicator from '../ActivityIndicator';
import DataTable from '../DataViews/DataTable';
import ErrorDisplay from '../ErrorDisplay';
import FilterControl, { FilterType, IFilter } from '../Filtering/FilterControl';
import ModuleContext from '../Modules/ModuleContext';
import ModuleLinkButton from '../Modules/ModuleLinkButton';
import PaginationControl from '../Pagination/PaginationControl';
import PreviousNextControl from '../Pagination/PreviousNextControl';
import { getSiteModuleRoute } from '../route-utils';
import SiteContext from '../Sites/SiteContext';
import FileBreadcrumbs from './FileBreadcrumbs';
import ListControls from '../Shared/ListControls';
import ActionButtons from '../Shared/ActionButtons';
import HorizontalLine from '../Shared/HorizontalLine';

const filterTypeMappings = new Map([
  [FileFilterBy.Name, FilterType.String],
  [FileFilterBy.CreatedDateTime, FilterType.Date],
]);

export default function Files(props: RouteComponentProps<{ fileTypeId: string }>) {
  const { match: { params: { fileTypeId } } } = props;

  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(FileOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[0],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[0],
  };

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const {
    loading, error, data, refetch, fetchMore,
  } = useGetMyFiles({
    siteId, module, fileTypeId, paginationProps,
  });

  const handleChangeFilter = (nextFilter: IFilter<FileFilterBy>) => {
    refetch({ filterProps: { filters: [nextFilter] } });
  };

  const handleChangePaginationProps = (nextPaginationProps: FilePaginationPropsType) => {
    refetch({ paginationProps: nextPaginationProps });
  };

  const handleFetchPrevious = () => {
    fetchMore({ variables: { before: data?.myFiles?.startCursor } });
  };

  const handleFetchNext = () => {
    fetchMore({ variables: { after: data?.myFiles?.endCursor } });
  };

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.fileType && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">{data.fileType.name}</Typography>
              <FileBreadcrumbs title={data.fileType.name} />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          <ActionButtons>
            <ModuleLinkButton
              to={`/file-types/${fileTypeId}/add`}
              aria-label="add"
              startIcon={<AddIcon />}
              tooltip="Create new file"
            >
              Create new
            </ModuleLinkButton>
          </ActionButtons>
        </>
      )}
      {data?.myFiles && (
        <Paper>
          <ListControls>
            <PaginationControl<FileOrderBy>
              initialProps={paginationProps}
              options={paginationOptions}
              onRefetch={handleChangePaginationProps}
            />
            <FilterControl<FileFilterBy>
              filterByValues={Object.values(FileFilterBy)}
              filterTypeMappings={filterTypeMappings}
              onChange={handleChangeFilter}
            />
            <PreviousNextControl
              hasPreviousPage={data.myFiles.hasPreviousPage}
              onFetchPrevious={handleFetchPrevious}
              hasNextPage={data.myFiles.hasNextPage}
              onFetchNext={handleFetchNext}
            />
          </ListControls>
          <HorizontalLine margin={[1, 0, 1, 0]} />
          <DataTable
            data={data.myFiles.items}
            itemRoute={getSiteModuleRoute(siteId, module, 'files/{id}')}
          />
          <ListControls>
            <PreviousNextControl
              hasPreviousPage={data.myFiles.hasPreviousPage}
              onFetchPrevious={handleFetchPrevious}
              hasNextPage={data.myFiles.hasNextPage}
              onFetchNext={handleFetchNext}
            />
          </ListControls>
        </Paper>
      )}
    </>
  );
}
