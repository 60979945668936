import { Grid, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { useGetRoles } from '../../../apollo-hooks';
import ProgressBar from '../../Shared/ProgressBar';
import ErrorDisplay from '../../ErrorDisplay';

interface IProps {
  roleId: string;
  onChange: (roleId: string) => void;
  required?: boolean;
}

export default function RolePickList(props: IProps) {
  const { roleId, onChange, required } = props;

  const { loading, error, data } = useGetRoles();

  const label = 'Select a role';
  const labelId = 'roleLabel';
  const controlId = 'roleControl';

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className="tillr-form-grid-element"
      >
        {loading && <ProgressBar />}
        {error && <ErrorDisplay error={error} />}
        {data && (
        <FormControl
          variant="outlined"
          required={required}
          fullWidth
        >
          <InputLabel id={labelId} htmlFor={controlId}>
            {label}
          </InputLabel>
          <Select
            labelId={labelId}
            id={controlId}
            onChange={handleChange}
            value={roleId}
            fullWidth
            label={label}
          >
            {data.roles?.slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
          </Select>
        </FormControl>
        )}
      </Grid>
    </>
  );
}
