import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useContext, useState } from 'react';
import { useCreateAttachments } from '../../apollo-hooks';
import { AttachmentFragment, CreateAttachmentsMutationVariables, ParentType } from '../../tillr-graphql';
import { uploadFiles } from '../../utils';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import SiteContext from '../Sites/SiteContext';
import NetworkStatusContext from '../../NetworkStatusContext';
import { IAppTheme } from '../../themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) => createStyles({
  dropzone: {
  },
  disabled: {
    backgroundColor: '#666',
    opacity: 0.3,
    cursor: 'default',
  },
  add: {
    margin: theme.spacing(1, 0),
  },
}));

interface IProps {
  parentId: string;
  parentType: ParentType;
  onOpenDialog?: () => void;
  onUploaded?: (attachments: AttachmentFragment[]) => void;
}

const acceptedFileMimeTypes = [
  'image/jpeg', // jpeg, jpg
  'image/gif',
  'image/bmp',
  'image/png',
  'video/mov',
  'video/mp4',
  'video/m4v',
  'video/m4v',
  'video/avi',
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument.text', // odt
  'text/plain', // text, txt
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'text/csv',
  'application/xml',
  'application/zip',
];

export default function AddAttachmentsControl(props: IProps) {
  const {
    parentId, parentType, onOpenDialog, onUploaded,
  } = props;
  const classes = useStyles();
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const parentProps = {
    siteId, module, parentId, parentType,
  };
  const networkStatusContext = useContext(NetworkStatusContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [createAttachment, { loading, data, error }] = useCreateAttachments(parentProps);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    if (onOpenDialog) {
      onOpenDialog();
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSave = (files: File[]) => {
    setFilesToUpload(files);
  };

  let uploading = false;
  if (filesToUpload.length) {
    if (!loading && !data && !error) {
      const fileNames = Array.from(filesToUpload).map((x) => x.name);
      const variables: CreateAttachmentsMutationVariables = { ...parentProps, fileNames };
      createAttachment({ variables });
    }
    if (data?.createAttachments) {
      uploading = true;
      const { uploadUrlTemplate, attachments } = data.createAttachments;
      const files = attachments.map((x, i) => ({ id: x.blobStorageId, file: filesToUpload[i] }));
      uploadFiles(uploadUrlTemplate, files)
        .then(() => {
          if (onUploaded) {
            onUploaded(attachments);
          }
          handleCloseDialog();
          setFilesToUpload([]);
        }, () => {
        })
        // eslint-disable-next-line no-alert
        .catch(() => true);
    }
  }

  const submitting = loading || uploading;

  return (
    <div>
      {error && <ErrorDisplay error={error} />}
      <Button
        variant="contained"
        color="secondary"
        aria-label="Add attachment"
        onClick={handleOpenDialog}
        disabled={!networkStatusContext}
        className={classes.add}
      >
        <AttachmentIcon />
        Add attachment
        {networkStatusContext ? '' : ' (connection lost)'}
      </Button>
      <DropzoneDialog
        open={isDialogOpen}
        acceptedFiles={acceptedFileMimeTypes}
        filesLimit={999}
        showAlerts
        showPreviews={isLargeScreen}
        onSave={handleSave}
        onClose={handleCloseDialog}
        // eslint-disable-next-line no-nested-ternary
        submitButtonText={!networkStatusContext ? 'connection lost' : (!submitting ? 'Submit' : 'Submitting...')}
        maxFileSize={20447232}
        dropzoneProps={{ disabled: !networkStatusContext }}
        dropzoneClass={networkStatusContext ? classes.dropzone : classes.disabled}
      />
    </div>
  );
}
