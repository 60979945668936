import React from 'react';
import { Redirect, Route, Router, Switch, useHistory } from 'react-router-dom';
import FormTemplates from './Forms/FormTemplates';
import Report from './Reports/Report';
import Reports from './Reports/Reports';
import Rules from './Rules/Rules';
import AddSite from './Sites/AddSite';
import Sites from './Sites/Sites';
import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';
import User from './Users/User';
import Users from './Users/Users';

export default function Home() {
  const history = useHistory();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin">
          <Redirect to="/admin/sites" />
        </Route>
        <Route exact path="/admin/form-templates" component={FormTemplates} />
        <Route exact path="/admin/form-templates/:formTemplateId/rules" component={Rules} />
        <Route exact path="/admin/reports" component={Reports} />
        <Route exact path="/admin/reports/:reportId" component={Report} />
        <Route exact path="/admin/sites" component={Sites} />
        <Route exact path="/admin/sites/:siteId/sites/add" component={AddSite} />
        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/users/add" component={AddUser} />
        <Route exact path="/admin/users/:userId" component={User} />
        <Route exact path="/admin/users/:userId/edit" component={EditUser} />
      </Switch>
    </Router>
  );
}
