import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.secondary': {
      color: theme.palette.secondary.main,
    },
  },
}));

interface IProps {
  variant?: 'contained' | 'outlined';
  label: string;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  loading?: boolean;
  onClick?: any;
  disabled?: boolean;
  type?: 'submit' | 'button';
  size?: 'large' | 'medium' | 'small';
  startIcon?: any;
  endIcon?: any;
  isValid?: Function | boolean;
}

export default function SubmitButton(props: IProps) {
  const classes = useStyles();
  const {
    variant = 'contained',
    label,
    color = 'primary',
    loading = false,
    onClick,
    disabled,
    type = 'button',
    size = 'medium',
    startIcon,
    endIcon,
    isValid = true,
  } = props;

  const Btn = React.forwardRef<any, any>((args, ref) => (
    <>
      <Button
        ref={ref}
        variant={variant}
        color={color}
        onClick={onClick}
        disabled={loading || disabled || !isValid}
        type={type}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        className="themed-submit-button"
      >
        {label}
      </Button>
      {loading && <CircularProgress size={24} className={clsx([classes.buttonProgress, color])} />}
    </>
  ));

  return (
    <Grid item className={classes.wrapper}>
      {isValid ? (
        <Btn />
      ) : (
        <Tooltip title="The form is invalid and cannot be saved. Ensure that all required fields are completed.">
          <span>
            <Btn />
          </span>
        </Tooltip>
      )}
    </Grid>
  );
}
