import { CircularProgress } from '@material-ui/core';
import React from 'react';

type Size = 'small' | 'medium' | 'large';

interface IProps {
  size?: Size;
}

function switchSize(size: Size) {
  switch (size) {
    case 'small': return '1rem';
    case 'medium': return '2rem';
    case 'large': return '4rem';
    default: return '2rem';
  }
}

export default function ActivityIndicator(props: IProps) {
  const { size = 'medium' } = props;

  return <CircularProgress size={switchSize(size)} />;
}
