import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useCreateFileNote } from '../../apollo-hooks';
import { CreateFileNoteMutationVariables } from '../../tillr-graphql';
import ErrorDisplay from '../ErrorDisplay';
import MagicForm from '../MagicForm';
import ModuleContext from '../Modules/ModuleContext';
import ModuleRedirect from '../Modules/ModuleRedirect';
import SiteContext from '../Sites/SiteContext';

export default function AddFileNote(props: RouteComponentProps<{ fileId: string }>) {
  const { match: { params: { fileId } } } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createFileNote, { loading, data, error }] = useCreateFileNote({
    siteId, module, id: fileId,
  });

  if (data?.createFileNote) {
    return <ModuleRedirect to={`/files/${fileId}`} />;
  }

  const handleSubmit = (model: CreateFileNoteMutationVariables) => {
    createFileNote({ variables: model });
  };

  const model: CreateFileNoteMutationVariables = {
    siteId, module, fileId, note: '',
  };

  return (
    <div>
      <h2>Create file note</h2>
      {error && <ErrorDisplay error={error} />}
      <MagicForm model={model} onSubmit={handleSubmit} submitting={loading} />
    </div>
  );
}
