import React, { useEffect, useContext } from 'react';
import { Typography } from '@material-ui/core';
import ErrorDisplay from '../ErrorDisplay';
import ActivityIndicator from '../ActivityIndicator';
import { useGetMyReport } from '../../apollo-hooks';
import ModuleContext from '../Modules/ModuleContext';
import ErrorBoundary from '../ErrorBoundary';
import { getReportConfig } from '../Reports/IReportConfig';
import useRunReport, { ReportIdType } from '../Reports/useRunReport';
import ReportDataView from '../Reports/ReportDataView';
import { IModel } from '../../formly/IModel';

interface IProps {
  reportId: string;
  inputParameters?: IModel;
}

export default function ReportWidget(props: IProps) {
  const { reportId, inputParameters = {} } = props;

  const { module } = useContext(ModuleContext)!;
  const getReportState = useGetMyReport({ module, id: reportId });
  const [runReport, runReportState] = useRunReport();

  useEffect(() => {
    runReport(reportId, ReportIdType.Report, inputParameters);
    // eslint-disable-next-line
  }, [reportId]);

  if (getReportState.error) {
    return <ErrorDisplay error={getReportState.error} />;
  }
  if (runReportState.error) {
    return <ErrorDisplay error={runReportState.error} />;
  }

  if (getReportState.loading || runReportState.loading) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      {getReportState.data?.myReport?.queryDefinition && runReportState.data ? (
        <>
          <Typography variant="h2" noWrap>
            {getReportState.data.myReport.name}
          </Typography>
          <ErrorBoundary>
            <ReportDataView
              inputParametersDefinition={[]}
              inputParameters={inputParameters}
              reportConfig={getReportConfig(getReportState.data.myReport.uiData)}
              data={runReportState.data}
              resultColumns={getReportState.data.myReport.queryDefinition.resultColumns}
            />
          </ErrorBoundary>
        </>
      ) : (
        <>
          <p>Not found.</p>
        </>
      )}
    </div>
  );
}
