import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

interface IProps {
  active: boolean;
}

export default function PreventTransition(props: IProps) {
  const { active: shouldConfirmNavigation } = props;
  return (
    <NavigationPrompt when={shouldConfirmNavigation}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog fullWidth maxWidth="xs" open={isActive} onClose={onCancel} aria-labelledby="dialog-title">
          <DialogTitle id="dialog-title">Are you sure you want to leave this page?</DialogTitle>
          <DialogContent>
            <Typography variant="body1" display="block">
              Any unsaved changes will be lost if you navigate away from this form.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary">
              No, stay
            </Button>
            <Button onClick={onConfirm} color="primary">
              Yes, continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </NavigationPrompt>
  );
}
