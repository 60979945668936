import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetTeamWithUsers } from '../../apollo-hooks';
import ActivityIndicator from '../ActivityIndicator';
import DataTable from '../DataViews/DataTable';
import Detail from '../DataViews/Detail';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import SiteContext from '../Sites/SiteContext';

export default function Team(props: RouteComponentProps<{ teamId: string }>) {
  const { match: { params: { teamId } } } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetTeamWithUsers({ siteId, module, id: teamId });

  return (
    <div>
      <Typography variant="h2" noWrap>Team</Typography>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data && (
        <>
          {data.team ? <Detail data={data.team} /> : !error && <p><i>Not found</i></p>}
          <h3>All users</h3>
          {data.users && <DataTable data={data.users} />}
        </>
      )}
    </div>
  );
}
