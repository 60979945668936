import React, { memo, ReactNode } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IData } from '../IFormlyConfig';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // width: '100%',
    margin: '12px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

interface IProps {
  children: ReactNode;
  data?: IData;
  index: number;
}

function CollapsableGroup(props: IProps) {
  const { children, data, index } = props;
  const classes = useStyles();

  return (
    <>
      <Accordion
        square
        className={classes.root}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`fb-group-${index}-content`}
          id={`fb-group-${index}-header`}
        >
          {data?.label ? (
            <Typography
              className={classes.heading}
            >
              {data.label}
            </Typography>
          ) : (
            <Typography
              className={classes.heading}
            >
              Group
              {` ${index}`}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default memo(CollapsableGroup);
