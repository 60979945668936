import { createMuiTheme, lighten } from '@material-ui/core/styles';

/*
$_2_primary_font: 'Lato', 'HelveticaNeue-Light', 'Helvetica Neue Light',
... 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$_2_secondary_font: 'Morebi', 'HelveticaNeue-Light', 'Helvetica Neue Light',
... 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$_2_primary: #009cde;
$_2_secondary: #484d93;
$_2_tertiary: #cd0067;
$_2_quaternary: #879c32;
$_2_quinary: #dcf3fa;
$_2_senary: #735496;
$_2_septenary: #d5e7ee;
*/

const siteCSSVars = {
  offlineMessage: {
    backgroundColor: '#2d3169',
    color: '#fff',
  },
  mandatory: {
    color: '#c80000',
  },
  disabledFormElements: {
    backgroundColor: '#f6f6f6',
    color: '#333',
  },
  assigneeSummary: {
    one: {
      backgroundColor: '#7C90A0',
      color: '#ffffff',
    },
    two: {
      backgroundColor: '#BCE7FD',
      color: '#111111',
    },
  },
  siteTitle: {
    width: '145px',
  },
  libraryFileIcon: {
    color: '#703291',
  },
  automatedTasks: {
    defaultColor: '#1c2430',
    colorLight: lighten('#1c2430', 0.9),
    colorDark: lighten('#1c2430', 0.8),
  },
  fixedFormButtons: {
    backgroundColor: '#f2f2f1',
  },
};

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#000',
    },
    background: {
      default: '#f2f2f1',
      paper: '#fff',
    },
    primary: {
      main: '#009cde',
    },
    secondary: {
      main: '#c81864',
    },
    error: {
      main: '#960200',
    },
    warning: {
      main: '#FFDDA1',
      dark: '#6e6962',
    },
    success: {
      main: '#628B48',
    },
    type: 'light',
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      '"HelveticaNeue-Light"',
      '"Helvetica Neue Light"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h2: {
      fontSize: '1.5rem',
      fontFamily: [
        '"Morebi"',
        '"HelveticaNeue-Light"',
        '"Helvetica Neue Light"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '2rem',
      fontFamily: [
        '"Morebi"',
        '"HelveticaNeue-Light"',
        '"Helvetica Neue Light"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    h4: {
      fontFamily: [
        '"Morebi"',
        '"HelveticaNeue-Light"',
        '"Helvetica Neue Light"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
});

theme.overrides = {
  MuiAccordion: {
    root: {
      width: '100%',
      margin: '12px',
      '&$expanded': {
        margin: '12px',
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      flexDirection: 'column',
    },
  },
  MuiAccordionSummary: {
    root: {
      backgroundColor: '#f6f6f6',
      '&$expanded': {
        backgroundColor: '#fff',
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: 'rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;',
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: '#009cde',
    },
  },
  MuiButton: {
    root: {
      '&.themed-submit-button': {
        color: '#fff',
        fontWeight: '700',
      },
    },
    contained: {
      backgroundColor: '#f2f2f1',
      color: '#6e6962',
      '&:hover': {
        color: '#f2f2f1',
        backgroundColor: '#6e6962',
      },
    },
    containedSecondary: {
      color: '#ffffff',
    },
  },
  MuiCheckbox: {
    colorSecondary: {
      '&$disabled': {
        '& .MuiSvgIcon-root': {
          color: siteCSSVars.disabledFormElements.color,
        },
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      margin: '24px 0',
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: '#484d93',
      '& h1': {
        backgroundColor: '#f2f2f1',
      },
      '& .MuiListItemText-root': {
        color: '#ffffff',
      },
      '& .MuiListItemIcon-root': {
        color: '#ffffff',
      },
      '& .MuiCollapse-wrapper': {
        backgroundColor: '#009cde',
      },
      '& .nav--footer': {
        backgroundColor: '#f2f2f1',
        '& .MuiListItemText-primary': {
          color: '#111111',
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
    asterisk: {
      color: siteCSSVars.mandatory.color,
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormControlLabel: {
    label: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiIconButton: {
    root: {
      '&.action-button': {
        color: '#4C2719',
        '&:hover': {
          color: '#edb540',
        },
      },
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        backgroundColor: siteCSSVars.disabledFormElements.backgroundColor,
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiRadio: {
    colorSecondary: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiTab: {
    labelIcon: {
      minHeight: '48px',
    },
    wrapper: {
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      '& > :first-of-type': {
        marginBottom: '0',
        marginRight: theme.spacing(1),
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.9rem',
      backgroundColor: '#197b7d',
      color: '#fff',
    },
    arrow: {
      color: '#197b7d',
    },
  },
};

const systemConceptsTheme = { ...theme, ...siteCSSVars };

export default systemConceptsTheme;
