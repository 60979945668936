import { ApolloError } from '@apollo/client';
import { Avatar, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Maybe, RegisteredUserFragment } from '../../../tillr-graphql';
import { getAvatarFromName } from '../../../utils';
import ErrorDisplay from '../../ErrorDisplay';
import ProgressBar from '../../Shared/ProgressBar';
import LoadingUserRow from './LoadingUserRow';
import SitesSummary from './SitesSummary';
import useDetectTenantType from '../../Custom/useDetectTenantType';

const useStyles = makeStyles((theme: Theme) => createStyles({
  info: {
    color: '#666',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));

interface IProps {
  data?: Maybe<RegisteredUserFragment[]>;
  loading: boolean;
  error?: ApolloError;
}

export default function RegisteredUsers(props: IProps) {
  const classes = useStyles();
  const { data, loading, error } = props;
  const history = useHistory();
  // const userProfile = useContext(UserProfileContext);
  // const tenantName = userProfile?.tenantName;
  const [tenantType] = useDetectTenantType();

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    const route = `/admin/users/${id}`;
    history.push(route);
  };

  const practiceAdmin = data?.filter((user) => user.role.name === 'Practice Admin');

  function Row(rowProps: { row: RegisteredUserFragment }) {
    const { row } = rowProps;
    return (
      <>
        <TableRow
          hover
          onClick={(event) => handleClick(event, row.id)}
          className="user-clickable"
        >
          <TableCell
            component="th"
            scope="row"
          >
            <div className="like-list-item-avatar">
              <Avatar>
                {getAvatarFromName(row.name)}
              </Avatar>
              <div className="like-list-item-avatar__text">
                <Typography variant="body1" noWrap display="block">{row.name}</Typography>
                <Typography variant="body2" noWrap display="block"> </Typography>
              </div>
            </div>
          </TableCell>
          <TableCell>
            {!row.role.name && (
              <Typography variant="body1">
                Not allocated to any site(s)
              </Typography>
            )}
            {row.role.id.toUpperCase().match(/C17BD0E8/) && (
              <Chip
                label={row.role.name}
                color="primary"
              />
            )}
            {!row.role.id.toUpperCase().match(/C17BD0E8/) && (
              <Typography variant="body1">
                {row.role.name}
              </Typography>
            )}
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              {row.email}
            </Typography>
          </TableCell>
          <TableCell>
            <SitesSummary sites={row.sites} />
          </TableCell>
        </TableRow>
      </>
    );
  }

  function Head() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="col--20">
            <Typography variant="body1">
              Name
            </Typography>
          </TableCell>
          <TableCell className="col--20">
            <Typography variant="body1">
              Role
            </Typography>
          </TableCell>
          <TableCell className="col--20">
            <Typography variant="body1">
              Email
            </Typography>
          </TableCell>
          <TableCell className="col--40">
            <Typography variant="body1">
              Sites
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      {error && <ErrorDisplay error={error} />}

      {loading && (
        <>
          <ProgressBar />
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <Head />
              <TableBody>
                <LoadingUserRow cells={4} />
                <LoadingUserRow cells={4} />
                <LoadingUserRow cells={4} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {data && (
        <>
          <Grid container>
            <Grid item>
              <Typography variant="body1" className={classes.info}>
                Total registered users:
                {` ${data.length}`}
              </Typography>
            </Grid>
            {tenantType === 'squirrel' && (
            <Grid item>
              <Typography variant="body1" className={classes.info}>
                Total Practice Admins:
                {` ${practiceAdmin?.length}`}
              </Typography>
            </Grid>
            )}
          </Grid>
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <Head />
                <TableBody>
                  {data.map((row: any) => (
                    <Row key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
}
