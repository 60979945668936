import { LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2000,
    width: '100%',
  },
}));

export default function ProgressBar() {
  const classes = useStyles();
  return <LinearProgress className={classes.root} />;
}
