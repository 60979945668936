import React, { useContext } from 'react';
import { useGetMyTask } from '../../apollo-hooks';
import FormlyForm from '../../formly/FormlyForm';
import getStaticTemplate from '../../formly/staticTemplates';
import ActivityIndicator from '../ActivityIndicator';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import SiteContext from '../Sites/SiteContext';
import { formatTaskAssignees } from './utils';

interface IProps {
  taskId: string;
}

export default function QuickView(props: IProps) {
  const { taskId } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetMyTask({ siteId, module, id: taskId });

  if (loading) {
    return <ActivityIndicator />;
  }
  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (data?.myTask) {
    const config = getStaticTemplate('task-quick-view');

    return (
      <FormlyForm
        formId={taskId}
        mode="readonly"
        config={config}
        model={formatTaskAssignees(data.myTask)}
        modal
      />
    );
  }

  return null;
}
