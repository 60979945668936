import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect } from 'react';
import { useGetTeamsAndUsers } from '../../apollo-hooks';
import ActivityIndicator from '../../components/ActivityIndicator';
import ErrorDisplay from '../../components/ErrorDisplay';
import ModuleContext from '../../components/Modules/ModuleContext';
import SiteContext from '../../components/Sites/SiteContext';
import { IComponentProps } from '../IComponentProps';
import withComponentPropsMemo from '../withComponentPropsMemo';
import { getRuleMessage, FieldValidationMessage, hasFieldError } from './shared/Validation';
// eslint-disable-next-line import/extensions
import { IAppTheme } from '../../themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) => createStyles({
  disabled: {
    '& .MuiInputBase-root': {
      backgroundColor: theme?.disabledFormElements?.backgroundColor,
      '& .MuiChip-root.Mui-disabled': {
        opacity: 1,
        '& .MuiChip-label': {
          color: theme?.disabledFormElements?.color,
        },
        '& .MuiChip-deleteIcon': {
          display: 'none',
        },
      },
    },
  },
}));

export interface IAssignee {
  id: any;
  name: string;
  type: 'User' | 'Team';
}

function Assignees(props: IComponentProps) {
  const classes = useStyles();
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState,
  } = props;
  const value = modelValue || [];
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetTeamsAndUsers({ siteId, module });

  const customTrigger = () => {
    const values = getValues();
    if (field.templateOptions?.required && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  useEffect(() => {
    register(
      { name: field.key },
    );
    setValue(field.key, value, { shouldDirty: true });
    customTrigger();
  // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
  // idea that an empty array will only call useEffect once?
  // eslint-disable-next-line
  }, []);

  const handleChange = (event: any, newValue: any[] | null) => {
    if (field.key) {
      onValueUpdate(field.key, newValue);
      setValue(field.key, newValue, { shouldDirty: true });
      customTrigger();
    }
  };

  let options: IAssignee[] = data && data.users && data.teams
    ? data.users.map((x) => ({ id: x.id, name: x.name, type: 'User' }) as IAssignee)
      .concat(data.teams.map((x) => ({ id: x.id, name: x.name, type: 'Team' }) as IAssignee))
    : [];
  options = options.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data && data.users && (
        <Grid
          item
          xs={12}
          sm={field.className?.match(/col-xs-6/) ? 6 : 12}
          className={field.className?.match(/pull-right/) ? 'tillr-form-grid-element tillr-form-grid-element--align-right' : 'tillr-form-grid-element'}
        >
          <Autocomplete
            multiple
            id="tags-standard"
            options={options}
            onChange={handleChange}
            getOptionLabel={(option) => option.name}
            disabled={readonly}
            value={value}
            className={readonly ? classes.disabled : ''}
            getOptionSelected={(option, v) => option.id === v.id}
            renderInput={(args) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...args}
                fullWidth
                label={field?.templateOptions?.label}
                variant="outlined"
                required={!readonly && field.templateOptions?.required}
                disabled={field.templateOptions?.disabled}
                error={hasFieldError(field.key!, errors, formState)}
              />
            )}
          />
          {hasFieldError(field.key!, errors, formState)
            && <FieldValidationMessage message={errors[field.key!].message} />}
        </Grid>
      )}
    </>
  );
}

export default withComponentPropsMemo(Assignees);
