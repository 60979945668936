import { ApolloProvider } from '@apollo/client';
import { User, UserManager } from 'oidc-client';
import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout/Layout';
import ModuleManager from './components/Modules/ModuleManager';
import SiteManager from './components/Sites/SiteManager';
import createApolloClient from './createApolloClient';
import createUserManager from './createUserManager';
import './styles/index.scss';
import UserManagerContext from './UserManagerContext';
import UserProfile from './UserProfile';
import UserProfileContext from './UserProfileContext';

interface IState {
  loaded: boolean;
  userProfile: UserProfile | null;
}

export default class App extends React.Component<{}, IState> {
  private readonly userManager: UserManager;

  constructor(props: {}) {
    super(props);

    this.state = { loaded: false, userProfile: null };
    this.userManager = createUserManager(this.handleUserChanged);
  }

  public componentDidMount() {
    this.userManager.getUser()
      .then((user) => {
        const userProfile = user ? new UserProfile(user) : null;
        this.setState({ loaded: true, userProfile });
      });
  }

  private handleUserChanged = (user: User | null) => {
    if (!user) {
      this.setState({ userProfile: null });
    } else {
      this.setState((prevState) => {
        const userProfile = new UserProfile(user);
        return ({ ...prevState, userProfile });
      });
    }
  };

  public render() {
    const { loaded, userProfile } = this.state;
    if (!loaded) {
      return <p>Loading...</p>;
    }

    const apolloClient = createApolloClient(userProfile);

    return (
      <UserManagerContext.Provider value={this.userManager}>
        <UserProfileContext.Provider value={userProfile}>
          <ApolloProvider client={apolloClient}>
            <Route path="(/site-)?:siteId?/:module?/*">
              <SiteManager>
                <ModuleManager>
                  <Layout />
                </ModuleManager>
              </SiteManager>
            </Route>
          </ApolloProvider>
        </UserProfileContext.Provider>
      </UserManagerContext.Provider>
    );
  }
}
