/* eslint-disable implicit-arrow-linebreak */
import { IFormula } from './IFormlyConfig';
import { IModel } from './IModel';

class Operations {
  static normalise = (model: IModel, key: string): number => {
    let value = model[key];
    // Scored checkboxes
    if (value?.constructor === Object && typeof value.total !== 'undefined') {
      value = value.total;
    }
    if (typeof value === 'boolean') {
      return value === true ? 1 : 0;
    }
    if (value?.match && value.match(/:[0-9.-]+/)) {
      [, value] = value.split(':');
    }
    return parseFloat(value) || 0;
  };

  static normaliseArray = (model: IModel, keys: string[]): number[] =>
    keys.map((k) => Operations.normalise(model, k));

  static sum = (model: IModel, operands: string[]) =>
    Operations.normaliseArray(model, operands).reduce((a, b) => a + b);

  static average = (model: IModel, operands: string[]) =>
    Operations.sum(model, operands) / operands.length;

  static max = (model: IModel, operands: string[]) =>
    Math.max(...Operations.normaliseArray(model, operands));

  static min = (model: IModel, operands: string[]) =>
    Math.min(...Operations.normaliseArray(model, operands));

  static count = (model: IModel, operands: string[]) =>
    operands.map((x) => model[x]).filter((x) => x === true).length;

  static divide = (model: IModel, operands: string[]) => {
    if ((!model[operands[0]]) || (!model[operands[1]])) {
      return 0;
    }
    if ((!model[operands[0]])) {
      return Operations.normalise(model, operands[1]);
    }
    if ((!model[operands[1]])) {
      return Operations.normalise(model, operands[0]);
    }
    return Operations.normalise(model, operands[0]) / Operations.normalise(model, operands[1]);
  };

  static subtract = (model: IModel, operands: string[]) => {
    if ((!model[operands[0]]) || (!model[operands[1]])) {
      return 0;
    }
    if ((!model[operands[0]])) {
      return Operations.normalise(model, operands[1]);
    }
    if ((!model[operands[1]])) {
      return Operations.normalise(model, operands[0]);
    }
    return Operations.normalise(model, operands[0]) - Operations.normalise(model, operands[1]);
  };

  static copy = (model: IModel, operands: string[]) => model[operands[0]] || 0;

  static multiply = (model: IModel, operands: string[]) =>
    Operations.normaliseArray(model, operands).reduce((a, b) => a * b, 1);

  static ceilToNone = (value: number) => Math.ceil(value);

  static ceilToOne = (value: number) => Math.ceil(value * 10) / 10;

  static ceil = (value: number) => Math.ceil(value * 100) / 100; // Legacy, so keep as named

  static floorToNone = (value: number) => Math.floor(value);

  static floorToOne = (value: number) => Math.floor(value * 10) / 10;

  static floor = (value: number) => Math.floor(value * 100) / 100; // Legacy, so keep as named

  static round = (value: number) => Math.round(value);

  static roundToOne = (value: number) => Math.round(value * 10) / 10;

  static roundToTwo = (value: number) => Math.round(value * 100) / 100;
}

const OperationsLookup: { [operation: string]: Function; } = {
  sum: Operations.sum,
  average: Operations.average,
  max: Operations.max,
  min: Operations.min,
  count: Operations.count,
  divide: Operations.divide,
  subtract: Operations.subtract,
  copy: Operations.copy,
  multiply: Operations.multiply,
  ceilToNone: Operations.ceilToNone,
  ceilToOne: Operations.ceilToOne,
  ceil: Operations.ceil,
  floorToNone: Operations.floorToNone,
  floorToOne: Operations.floorToOne,
  floor: Operations.floor,
  round: Operations.round,
  roundToOne: Operations.roundToOne,
  roundToTwo: Operations.roundToTwo,
};

export default function evaluateFormula(formula: IFormula, model: IModel) {
  let result;
  if (formula.operation[0] === 'custom') {
    try {
      // eslint-disable-next-line no-new-func, @typescript-eslint/no-implied-eval
      result = new Function('scope', `return ${formula.operands}`)({ model });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`FORMLY: 'custom' formula threw an error:\r\n${e}`);
      return undefined;
    }
  } else {
    result = OperationsLookup[formula.operation[0]](model, formula.operands);
  }
  if (formula.operation[1]) {
    result = OperationsLookup[formula.operation[1]](result);
    if (formula.operation[1].toLowerCase().match(/one/)) {
      result = result.toFixed(1);
    } else if (formula.operation[1].toLowerCase().match(/two/)) {
      result = result.toFixed(2);
    }
  }
  if (typeof result === 'string'
    && (result.toLowerCase() === 'nan' || result.toLowerCase() === 'infinity')) {
    result = '0';
  }
  return result;
}
