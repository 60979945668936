import { Typography } from '@material-ui/core';
import React from 'react';
import Alert from './Alert';

interface IProps {
  children: React.ReactNode;
}

export default function InfoTextBlock(props: IProps) {
  const { children } = props;
  return (
    <Alert type="btw">
      <Typography variant="body1" display="block">
        {children}
      </Typography>
    </Alert>
  );
}
