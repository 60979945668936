import React, { lazy, Suspense } from 'react';
import ActivityIndicator from '../ActivityIndicator';

const Home = lazy(() => import(/* webpackChunkName: "reports" */ './Home'));

export default function WebpackChunkWrapper() {
  return (
    <Suspense fallback={<ActivityIndicator />}>
      <Home />
    </Suspense>
  );
}
