import React from 'react';
import AuthControls from './AuthControls';

export default function Unauthorised() {
  return (
    <>
      <h1>Unauthorised</h1>
      <AuthControls />
    </>
  );
}
