import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  title?: string;
  subtitle?: string;
  sublink?: string;
}

export default function AdminBreadcrumbs(props: IProps) {
  const { title, subtitle, sublink } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" component={RouterLink} to="/">
        Dashboard
      </Link>
      {title !== 'Admin' && (
        <Link color="inherit" component={RouterLink} to="/admin">
          Admin
        </Link>
      )}
      {subtitle && (
        <Link color="inherit" component={RouterLink} to={`/admin/${sublink}`}>
          {subtitle}
        </Link>
      )}
      <Typography color="textPrimary">{title}</Typography>
    </Breadcrumbs>
  );
}
