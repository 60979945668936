import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import ProgressBar from 'react-progressbar-on-scroll';
import { IAppTheme } from '../../themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) => createStyles({
  root: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  fixed: {
    boxShadow: '0px -1px 5px 0px rgba(100, 100, 100, 0.6)',
    backgroundColor: theme?.fixedFormButtons?.backgroundColor,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  fixedLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
  },
}));

interface IProps {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  justify?: 'flex-start' | 'flex-end';
  fixedButtons?: boolean;
}

export default function SubmitButton(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    children,
    direction = 'row',
    justify = 'flex-end',
    fixedButtons = false,
  } = props;

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getFormButtonPosition = () => {
    const fixedFormButtons = document.getElementById('fixed-form-buttons');
    if (!fixedFormButtons) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting === true) {
        fixedFormButtons.classList.remove('show-fixed');
        fixedFormButtons.classList.add('hide-fixed');
      } else {
        fixedFormButtons.classList.remove('hide-fixed');
        fixedFormButtons.classList.add('show-fixed');
      }
    }, { threshold: [1] });
    // eslint-disable-next-line consistent-return
    return observer;
  };

  useEffect(() => {
    const formButtons = document.getElementById('form-buttons');
    let o: IntersectionObserver | null | undefined = null;
    if (fixedButtons && formButtons) {
      o = getFormButtonPosition();
      o?.observe(formButtons);
    }
    return () => {
      if (o) o.disconnect();
    };
  }, [fixedButtons]);

  return (
    <>
      <Grid
        alignItems="center"
        container
        direction={direction}
        justify={justify}
        className={classes.root}
        id="form-buttons"
      >
        {children}
      </Grid>
      {fixedButtons && (
        <div
          id="fixed-form-buttons"
          className={`fixed-wrapper ${classes.fixed}`}
        >
          <ProgressBar
            color={theme.palette.primary.main}
            gradient={false}
            height={4}
          />
          <div className={classes.fixedLink}>
            <Button onClick={handleClick} color="primary">
              back to top
            </Button>
          </div>
          <div>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
