import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IComponentProps } from '../IComponentProps';
import withComponentPropsMemo from '../withComponentPropsMemo';

function Hr(props: IComponentProps) {
  const { field } = props;

  return (
    <>
      { field.className?.match(/clear/) && <hr className="tillr-form-grid-element tillr-form-grid-element--break" /> }
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={field.className?.match(/pull-right/) ? 'tillr-form-grid-element tillr-form-grid-element--align-right' : 'tillr-form-grid-element'}
      >
        <hr />
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Hr);
