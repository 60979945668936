import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorDisplay from './ErrorDisplay';

interface IProps {
  children: ReactNode;
}

interface IState {
  error?: Error;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public static getDerivedStateFromError(error: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { error } = this.state;
    if (error) {
      return <ErrorDisplay errorMessage={error.message} />;
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
