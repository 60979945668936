import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
  },
}));

interface IProps {
  children: React.ReactNode;
}

export default function ActionButtons(props: IProps) {
  const classes = useStyles();
  const { children } = props;
  return (
    <Grid container justify="flex-end" alignItems="center" className={classes.root}>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
}
