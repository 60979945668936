import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGetFileType } from '../../apollo-hooks';
import ModuleContext from '../Modules/ModuleContext';
import { getSiteModuleRoute } from '../route-utils';
import SiteContext from '../Sites/SiteContext';

interface IProps {
  fileTypeId?: string;
  title?: string;
}

export default function FileBreadcrumbs(props: IProps) {
  const { fileTypeId, title } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" component={RouterLink} to="/">
        Dashboard
      </Link>
      {title !== 'Files' && (
        <Link color="inherit" component={RouterLink} to={getSiteModuleRoute(siteId, module, 'file-types')}>
          Files
        </Link>
      )}
      {fileTypeId && (
        <FileTypeBreadcrumb fileTypeId={fileTypeId} />
      )}
      <Typography color="textPrimary">{title}</Typography>
    </Breadcrumbs>
  );
}

function FileTypeBreadcrumb(props: { fileTypeId: string }) {
  const { fileTypeId } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetFileType({ module, id: fileTypeId });

  if (loading && !error) {
    // REVIEW: is this ugly?
    return <Typography color="textPrimary">loading...</Typography>;
  }

  if (data?.fileType) {
    return (
      <Link
        color="inherit"
        component={RouterLink}
        to={getSiteModuleRoute(siteId, module, `file-types/${data.fileType.id}`)}
      >
        {data.fileType.name}
      </Link>
    );
  }

  return null;
}
