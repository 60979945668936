import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import AdminBreadcrumbs from '../AdminBreadcrumbs';
import AdminNavigation from '../AdminNavigation';
import TitlePrefix from '../../Shared/TitlePrefix';

export default function FormTemplates() {
  // TODO: Reinstate (taken out while adding filtering/pagination to Form Templates)
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Forms
            </>
          </Typography>
          <AdminBreadcrumbs title="Forms" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <Divider className="divider" />

      <AdminNavigation path="/admin/form-templates" />
    </>
  );
}
