import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Box, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { useGetFormTemplateAndInstances, useUpdateForm, useCreateForm } from '../../apollo-hooks';
import { UpdateFormInputType, CreateFormInputType, CreateFormMutationVariables, UpdateFormMutationVariables, FormOrderBy, PaginationOrder } from '../../tillr-graphql';
import DocumentsList from './DocumentsList';
import useRunReportToJson from '../Reports/useRunReportToJson';
import SiteContext from '../Sites/SiteContext';
import ModuleContext from '../Modules/ModuleContext';
import { generateUuid } from '../../utils';
import { IFormlyField } from '../../formly/IFormlyConfig';
import { IModel } from '../../formly/IModel';
import ProgressBar from '../Shared/ProgressBar';
import ErrorDisplay from '../ErrorDisplay';
import { FormMode } from '../../formly/FormMode';
import DocumentStatus from './DocumentsStatus';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: '100%',
  },
  dashboardText: {
    '& h2': {
      fontSize: 22,
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(0, 0, 1, 0),
      borderBottom: 'solid 1px #ccc',
      color: '#111',
    },
    '& p': {
      margin: theme.spacing(0, 0, 2, 0),
      fontSize: 12,
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface IForm {
  createdByUser?: {
    id: string;
    name: string;
  };
  createdDateTime?: string;
  formDataJson?: string;
  id?: string;
  isSubmitted?: boolean;
  name: string;
  parentId?: null;
  template: string;
  templateId?: string;
  typename?: string;
}

interface IProps {
  // statussm?: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  // statusmd?: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  // statuslg?: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  // listsm?: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  // listmd?: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  // listlg?: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  hasPermissions: number;
}

export default function Documents(props: IProps) {
  const classes = useStyles();
  const { hasPermissions } = props;
  const reportId = '4a542859-0986-476a-86cd-4831ac9610da';
  const [runReport, { loading, data, error }] = useRunReportToJson(reportId);
  const formTemplateId = 'fd52a49e-6942-426c-8bf3-3754dd360bcc'; // some items
  const paginationOptions = {
    pageSizes: [1],
    orderBys: Object.values(FormOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[0],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[1],
  };
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const {
    loading: templateLoading,
    error: templateError,
    data: templateData,
    refetch,
  } = useGetFormTemplateAndInstances({
    siteId, module, formTemplateId, paginationProps,
  });
  let docFormId: string = generateUuid();
  let docFormModel: IModel = {};
  let docFormConfig = { fields: [] };
  let docFormMode: FormMode = 'create';
  const [updateForm, updateMutationState] = useUpdateForm({ siteId, module, id: docFormId });
  const [createForm, createMutationState] = useCreateForm();
  // https://blog.logrocket.com/how-when-to-force-react-component-re-render/
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  if (updateMutationState.data?.updateForm || createMutationState.data?.createForm) {
    refetch();
  }

  // Calculate layout depending on how many previous Dashboard components have been rendered
  let statussm: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  let statusmd: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  let statuslg: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  let listsm: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  let listmd: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  let listlg: boolean | 'auto' | 2 | 12 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | undefined;
  switch (hasPermissions) {
    case 2:
      statussm = 12;
      statusmd = 8;
      statuslg = 8;
      listsm = 12;
      listmd = 12;
      listlg = 12;
      break;
    case 1:
      statussm = 12;
      statusmd = 12;
      statuslg = 4;
      listsm = 12;
      listmd = 12;
      listlg = 8;
      break;
    default:
      statussm = 12;
      statusmd = 4;
      statuslg = 4;
      listsm = 12;
      listmd = 12;
      listlg = 12;
  }

  function mergeReportDataAndTemplate(): any {
    if (!data) {
      return { fields: [] };
    }

    let formToUse: IForm | undefined | null = templateData?.forms?.items[0];
    let templateToUse = formToUse?.template;
    if (formToUse?.formDataJson) {
      docFormMode = 'manage';
      docFormModel = JSON.parse(formToUse.formDataJson);
    }
    if (formToUse?.id) {
      docFormId = formToUse.id;
    }
    if (!templateData?.forms?.items.length) {
      formToUse = templateData?.formTemplate;
      templateToUse = formToUse?.template;
    }

    if (!templateToUse) {
      return { fields: [] };
    }

    const formattedConfig = {
      fields: JSON.parse(templateToUse).fields[0].fieldGroup.map((field: IFormlyField) => {
        Object.defineProperty(field.data, 'completed', {
          // TODO: This is bad, but we KNOW there are only a maximum of 12,
          // so it's not going to kill anyone
          value: data.filter((document) => document['Document type'] === field?.templateOptions?.label).sort((a, b) => (new Date(b['Created date']) as any) - (new Date(a['Created date']) as any)),
          writable: true,
          enumerable: true,
        });
        Object.defineProperty(field, 'type', {
          value: 'dashboardDocumentsCheckbox',
          writable: true,
          enumerable: true,
        });
        // TODO: Right place for this? Was an afterthough
        if (docFormMode === 'create' && field.defaultValue) {
          docFormModel[field.key as string] = field.defaultValue;
        }
        return field;
      }),
    };

    return formattedConfig;
  }

  useEffect(() => {
    runReport({});
    // eslint-disable-next-line
  }, [siteId]);

  if (templateData && data) {
    docFormConfig = mergeReportDataAndTemplate();
  }

  const handleRefetch = () => {
    forceUpdate();
  };

  const handleSubmit = (model: IModel) => {
    if (docFormMode === 'manage') {
      const form: UpdateFormInputType = {
        id: docFormId,
        name: 'VP Documents Status',
        formDataJson: JSON.stringify(model),
        isSubmitted: false,
      };

      const variables: UpdateFormMutationVariables = { siteId, module, form };
      updateForm({ variables });
      return;
    }
    const form: CreateFormInputType = {
      id: docFormId,
      name: 'VP Documents Status',
      templateId: formTemplateId,
      formDataJson: JSON.stringify(model),
      isSubmitted: false,
    };

    const variables: CreateFormMutationVariables = { siteId, module, form };
    createForm({ variables });
  };

  return (
    <>
      <Grid item sm={statussm} md={statusmd} lg={statuslg}>
        <Paper className={classes.paper}>
          <div>
            <div className={classes.dashboardText}>
              <Typography variant="h2" display="block">
                Property Compliance Status
              </Typography>
              <Typography variant="body2" display="block">
                Status of required documents.
              </Typography>
            </div>
            {(loading || templateLoading) && (
              <Box
                height={400}
                className={classes.loading}
              >
                <ProgressBar />
                <CircularProgress />
              </Box>
            )}
            {(error || templateError) && <ErrorDisplay errorMessage="Something has gone wrong" />}
            {!loading && templateData && data && (
            <DocumentStatus
              config={docFormConfig}
              model={docFormModel}
            />
            )}
          </div>
        </Paper>
      </Grid>
      <Grid item sm={listsm} md={listmd} lg={listlg}>
        <Paper className={classes.paper}>
          <div>
            <div className={classes.dashboardText}>
              <Typography variant="h2" display="block">
                Property Compliance List
              </Typography>
            </div>
            {(loading || templateLoading) && (
              <Box
                height={400}
                className={classes.loading}
              >
                <ProgressBar />
                <CircularProgress />
              </Box>
            )}
            {(error || templateError) && <ErrorDisplay errorMessage="Something has gone wrong" />}
            {!loading && templateData && data && (
            <DocumentsList
              formId={docFormId}
              mode={docFormMode}
              config={docFormConfig}
              model={docFormModel}
              onSubmit={handleSubmit}
              onCancel={handleRefetch}
            />
            )}
          </div>
        </Paper>
      </Grid>
    </>
  );
}
