import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import NoteIcon from '@material-ui/icons/Note';
import React, { useContext } from 'react';
import { useCreateTaskNote } from '../../apollo-hooks';
import FormlyForm from '../../formly/FormlyForm';
import getStaticTemplate from '../../formly/staticTemplates';
import { CreateTaskNoteMutationVariables } from '../../tillr-graphql';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import SiteContext from '../Sites/SiteContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    marginBottom: theme.spacing(2),
  },
}));

interface IAddNoteProps {
  taskId: string;
  onCreated: () => void;
}

export default function AddTaskNote(props: IAddNoteProps) {
  const classes = useStyles();
  const { taskId, onCreated } = props;
  const [open, setOpen] = React.useState(false);

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createTaskNote, { loading, error }] = useCreateTaskNote({ siteId, module, id: taskId });

  const handleSubmit = (model: CreateTaskNoteMutationVariables) => {
    createTaskNote({ variables: model });

    setOpen(false);
    onCreated();
  };

  const model: CreateTaskNoteMutationVariables = {
    siteId, module, taskId, note: '',
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const config = getStaticTemplate('task-note');

  return (
    <>
      <Tooltip title="Add a note">
        <Button
          className={classes.button}
          variant="contained"
          aria-label="quick add"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<NoteIcon />}
        >
          Add a note
        </Button>
      </Tooltip>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Note</DialogTitle>
        <DialogContent>
          {error && <ErrorDisplay error={error} />}
          <FormlyForm
            formId={taskId}
            mode="create"
            config={config}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            model={model}
            submitting={loading}
            modal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
