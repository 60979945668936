interface IPivot {
  keyColumn: string;
  keyColumnFormat?: string;
  valueColumn: string;
}

interface IView {
  title: string;
  type: string;
  groupBy?: string;
  isGroupByMonth?: boolean;
  thenGroupBy?: string;
  // Set this if the data row includes a count as one of the columns.
  // If left undefined, each row will be counted as 1.
  countColumn?: string;
  initialFilterOn?: {
    column: string;
    value: string | number | boolean;
  };
}

export interface IReportConfig {
  inputOptions?: Record<string, string[]>;
  pivot?: IPivot;
  views: IView[];
}

export function getReportConfig(uiData: string | null | undefined): IReportConfig {
  if (uiData) {
    return JSON.parse(uiData) as IReportConfig;
  }
  // Default to Table view
  return { views: [{ title: '', type: 'Table' }] };
}
