import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import ProgressSkeleton from '../../Shared/ProgressSkeleton';

interface IProps {
  cells: number;
}

export default function Users(props: IProps) {
  const { cells } = props;
  return (
    <TableRow>
      {[...Array(cells)].map((e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableCell key={i}>
          <Typography variant="body1">
            <ProgressSkeleton />
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
}
