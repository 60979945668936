import React from 'react';
import { useHistory } from 'react-router-dom';
import './Header.scss';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import UserProfileContext from '../../UserProfileContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  orange: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  alert: {
    color: '#f00',
  },
}));

interface ISettings {
  // eslint-disable-next-line react/no-unused-prop-types
  drawerOpen: boolean;
  toggleDrawerOpen: () => void;
}

export default function Header(props: ISettings) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { toggleDrawerOpen } = props;
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    history.push('/logout');
  };
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="inherit"
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawerOpen}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          <UserProfileContext.Consumer>
            {(userProfile) => (userProfile
              ? `${userProfile.tenantName}`
              : 'Welcome'
            )}
          </UserProfileContext.Consumer>
        </Typography>
        <UserProfileContext.Consumer>
          {(userProfile) => (userProfile
          && (
            <>
              <Tooltip title="Your profile">
                <IconButton
                  color="inherit"
                  aria-label="profile options"
                  aria-haspopup="true"
                  aria-controls="profile-menu"
                  onClick={handleClick}
                >
                  <Avatar className={classes.orange}>{`${userProfile.getAvatarName()}`}</Avatar>
                </IconButton>
              </Tooltip>
              <Popover
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <List>
                  <ListItem>
                    <ListItemText primary={`${userProfile.name}`} />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary="Log out" />
                  </ListItem>
                </List>
                {
                /*
                <Divider />
                <List>
                  <ListItem>
                    <FormControlLabel
                      label="Dark theme"
                      control={
                      (
                        <Switch
                          onClick={toggleDarkTheme}
                        />
                      )
                    }
                    />
                  </ListItem>
                </List>
                */
                }
              </Popover>
            </>
          )
          )}
        </UserProfileContext.Consumer>
      </Toolbar>
    </AppBar>
  );
}
