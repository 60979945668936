function getUrlPrefix(): string {
  if (window.location.hostname.startsWith('local')) {
    // e.g. localhost:3000, localmy3.tillr.io
    return 'local';
  }
  if (window.location.hostname.startsWith('test')) {
    return 'test';
  }
  return '';
}

export function getApiBaseUrl(): string {
  // Set in your local machine's environment variables, then restart VS Code, to override this.
  const envVariable = (process.env as { [key: string]: string }).REACT_APP_API_BASE_URL;
  if (envVariable) {
    return envVariable;
  }

  const urlPrefix = getUrlPrefix();
  if (urlPrefix === 'local') {
    return 'http://localhost:7071';
  }
  return `https://${urlPrefix}api3.tillr.io`;
}

export function isProd(): boolean {
  return getUrlPrefix() === '';
}

interface IOidcConfig {
  oidcProvider: string;
  clientId: string;
  authRedirect: string;
  silentRedirect: string;
}

export function getOidcConfig(): IOidcConfig {
  const urlPrefix = getUrlPrefix();
  let clientDomain;
  let clientId;

  if (urlPrefix === 'local') {
    clientDomain = 'http://localhost:3000';
    clientId = 'my3.tillr.client';
  } else {
    const { hostname } = window.location;
    const subdomain = hostname.substring(urlPrefix.length, hostname.indexOf('.'));
    clientDomain = `https://${urlPrefix}${subdomain}.tillr.io`;
    clientId = `${subdomain}.tillr.client`;
  }

  return {
    oidcProvider: `https://${urlPrefix}accounts.tillr.io/core/`,
    clientId,
    authRedirect: `${clientDomain}/authenticated/`,
    silentRedirect: `${clientDomain}/authenticated/silent-renew`,
  };
}
