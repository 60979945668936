import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { useGetTeams } from '../../apollo-hooks';
import ActivityIndicator from '../ActivityIndicator';
import DataTable from '../DataViews/DataTable';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';

export default function Teams() {
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetTeams({ module });

  return (
    <div>
      <Typography variant="h2" noWrap>Teams</Typography>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.teams && <DataTable data={data?.teams} itemRoute="teams/{id}" />}
    </div>
  );
}
