import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { SiteType } from '../../tillr-graphql';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: '#fcefe3',
      color: theme.palette.primary.main,
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  paperRoot: {
    padding: theme.spacing(3),
    margin: theme.spacing(3, 0, 0),
  },
  content: {
    marginBottom: 2,
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: '#000',
    '& .MuiTypography-body2': {
      fontSize: '1rem',
      color: theme.palette.text.primary,
    },
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

interface StyledTreeItemProps {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  nodeId: string;
  children: React.ReactNode;
}

interface IProps {
  open: boolean;
  sites: Array<(
    Pick<SiteType, 'id' | 'name' | 'parentSiteId'>
  )>;
  onChange: (siteId: number) => void;
  onClose: () => void;
}

export default function SitesTreeView(props: IProps) {
  const { open, sites, onChange, onClose } = props;

  const classes = useStyles();

  const handleSiteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const target = (event.target as Element).closest('li.MuiTreeItem-root');
    if (target && (target as HTMLElement)?.dataset?.siteId) {
      const id = (target as HTMLElement)?.dataset?.siteId;
      onChange(Number(id));
    }
    onClose();
  };

  function StyledTreeItem(props: StyledTreeItemProps) {
    const {
      labelText,
      labelIcon: LabelIcon,
      labelInfo,
      color,
      bgColor,
      nodeId,
      ...other
    } = props;

    return (
      <TreeItem
        nodeId={nodeId}
        label={(
          <div className={classes.labelRoot}>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        )}
        data-site-id={nodeId}
        onIconClick={handleSiteClick}
        onLabelClick={handleSiteClick}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    );
  }

  const renderTree = (sites: SiteType[], parentSiteId: number | null) => {
    let childSites = parentSiteId
      ? sites.filter((x) => x.parentSiteId === parentSiteId)
      // All sites that don't have a parent also in the list
      : sites.filter((x) => !sites.map((y) => y.id).includes(x.parentSiteId || 0));
    childSites = childSites.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (childSites.length > 0) {
      return (
        <>
          {childSites.map((x) => (
            <StyledTreeItem
              nodeId={(x.id).toString()}
              key={x.id}
              labelText={x.name}
              labelIcon={SupervisorAccountIcon}
              labelInfo="90"
            >
              {renderTree(sites, x.id)}
            </StyledTreeItem>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">Switch site</DialogTitle>
      <DialogContent dividers>
        <TreeView
          className={classes.root}
          expanded={sites.map((site) => (site.id).toString())}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {renderTree(sites, null)}
        </TreeView>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel and close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
