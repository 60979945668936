import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  'aria-label'?: string;
  startIcon?: React.ReactNode;
  tooltip?: string;
}

export default function LinkButton(props: React.PropsWithChildren<IProps>) {
  const {
    to,
    'aria-label': ariaLabel,
    startIcon,
    tooltip,
    children,
  } = props;

  const button = (
    <Button
      className="no-wrap"
      color="secondary"
      variant="contained"
      component={Link}
      to={to}
      aria-label={ariaLabel}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
}
