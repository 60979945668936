import React from 'react';
import { IComponentProps } from '../IComponentProps';
import withComponentPropsMemo from '../withComponentPropsMemo';

function Hidden(props: IComponentProps) {
  const { field, modelValue } = props;

  return (
    <div>
      <input
        type="hidden"
        name={field.key}
        value={modelValue || ''}
      />
    </div>
  );
}

export default withComponentPropsMemo(Hidden);
