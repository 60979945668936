import { Avatar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import MovieIcon from '@material-ui/icons/Movie';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react';
// eslint-disable-next-line import/extensions
import { IAppTheme } from '../../themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) => createStyles({
  avatar: {
    backgroundColor: 'transparent',
    color: theme?.libraryFileIcon?.color,
  },
  icon: {
    fontSize: '2.4rem',
  },
}));

interface IProps {
  file: string;
}

export default function FileIcon({ file }: IProps) {
  const classes = useStyles();
  function IconType() {
    let icon = <DescriptionIcon className={classes.icon} />;
    const suffix = file.substring(file.lastIndexOf('.') + 1).toLowerCase();
    const imageTypes = [
      'jpeg',
      'gif',
      'bmp',
      'png',
    ];
    const videoTypes = [
      'mov',
      'mp4',
      'm4v',
      'avi',
    ];
    if (imageTypes.includes(suffix)) {
      icon = <ImageIcon className={classes.icon} />;
    }
    if (videoTypes.includes(suffix)) {
      icon = <MovieIcon className={classes.icon} />;
    }
    if (suffix === 'pdf') {
      icon = <PictureAsPdfIcon className={classes.icon} />;
    }
    return icon;
  }
  return (
    <Avatar color="default" className={classes.avatar}>
      <IconType />
    </Avatar>
  );
}
