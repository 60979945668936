import { IFormlyConfig } from '../../formly/IFormlyConfig';
import { IModel } from '../../formly/IModel';
import { FileTypeType } from '../../tillr-graphql';

// FileTypes helpers
export interface IUiData {
  materialIcon?: string;
  order?: number;
}

export function parseAndSortFileTypes(fileTypes: Pick<FileTypeType, 'id' | 'name' | 'uiData'>[]) {
  return fileTypes.map((x) => ({ ...x, uiData: JSON.parse(x.uiData) as IUiData }))
    // Use 999 so that FileTypes with no order set come after those that do.
    .sort((a, b) => (a.uiData.order ?? 999) - (b.uiData.order ?? 999));
}

// Formly helpers
const fileNameFieldKey = 'fileName';

export function buildFormlyConfig(template: string) {
  const config = JSON.parse(template) as IFormlyConfig;
  const fileNameField = {
    key: fileNameFieldKey,
    type: 'input',
    templateOptions: { label: 'Name', required: true },
  };
  config.fields.splice(0, 0, fileNameField);
  return config;
}

export function buildModel(name: string, formDataJson: string) {
  const model = JSON.parse(formDataJson) as IModel;
  model[fileNameFieldKey] = name;
  return model;
}

export function splitModel(model: IModel) {
  const fileName = model[fileNameFieldKey];
  // eslint-disable-next-line no-param-reassign
  delete model[fileNameFieldKey];
  const formDataJson = JSON.stringify(model);
  return [fileName, formDataJson];
}
