/* eslint-disable react/jsx-props-no-spreading */
import { AppointmentModel, ViewState } from '@devexpress/dx-react-scheduler';
import { Appointments, DateNavigator, MonthView, Scheduler, Toolbar } from '@devexpress/dx-react-scheduler-material-ui';
import { Box, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useGetMyTasks } from '../../../apollo-hooks';
import { PaginationOrder, TaskOrderBy } from '../../../tillr-graphql';
import ActivityIndicator from '../../ActivityIndicator';
import ErrorDisplay from '../../ErrorDisplay';
import ModuleContext from '../../Modules/ModuleContext';
import SiteContext from '../../Sites/SiteContext';
import SwitchViewControl from '../SwitchViewControl';
import AppointmentWithQuickView from './AppointmentWithQuickView';
import TimeTableCellWithAddTask from './TimeTableCellWithAddTask';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function TasksCalendar() {
  // TODO: Date range
  const paginationProps = {
    pageSize: 999,
    orderBy: TaskOrderBy.DueDate,
    order: PaginationOrder.Asc,
  };
  const classes = useStyles();

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const {
    loading, data, error, refetch,
  } = useGetMyTasks({ siteId, module, paginationProps });

  const appointments: AppointmentModel[] | undefined = data?.myTasks?.items.map((x) => {
    const startDate = x.dueDate as Date;
    const endDate = new Date(startDate);
    endDate.setHours(endDate.getHours() + 1);
    return {
      id: x.id,
      title: x.name,
      startDate,
      endDate,
      allDay: true,
    };
  });

  const appointmentComponent = (props: Appointments.AppointmentProps) => {
    const { data: { id: taskId } } = props;
    return <AppointmentWithQuickView {...props} taskId={taskId as string} />;
  };

  const timeTableCellComponent = (props: MonthView.TimeTableCellProps) => (
    <TimeTableCellWithAddTask {...props} onTaskCreated={refetch} />
  );

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.myTasks?.items && (
        <>
          <Box className={classes.root}>
            <SwitchViewControl currentView="calendar" />
          </Box>
          <Paper>
            <Scheduler data={appointments}>
              <ViewState />
              <MonthView
                timeTableCellComponent={timeTableCellComponent}
              />
              <Appointments
                appointmentComponent={appointmentComponent}
              />
              <Toolbar />
              <DateNavigator />
            </Scheduler>
          </Paper>
        </>
      )}
    </>
  );
}
