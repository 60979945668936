import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useContext, useState } from 'react';
import { useCreateTag, useGetTags } from '../../apollo-hooks';
import { CreateTagMutationVariables, ParentType } from '../../tillr-graphql';
import UserProfileContext from '../../UserProfileContext';
import ActivityIndicator from '../ActivityIndicator';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import DeleteTagControl from './DeleteTagControl';
import { miscColors } from '../../themes/default';

const useStyles = makeStyles((theme: Theme) => createStyles({
  chips: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.25),
    },
  },
}));
interface ITag {
  id: string;
  value: string;
}
interface IProps {
  tags: ITag[],
  parentType: ParentType;
  parentId: string;
  includeModule?: boolean;
  onTagCreated?: (tag: { id: string, value: string }) => void;
  onTagDeleted?: (tagId: string) => void;
}

export default function TagEditor(props: IProps) {
  const classes = useStyles();
  const filter = createFilterOptions<string>();
  const {
    tags, parentType, parentId, includeModule, onTagCreated, onTagDeleted,
  } = props;

  const userProfile = useContext(UserProfileContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createTag, {
    loading, data, error, reset,
  }] = useCreateTag();
  const [value, setValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: tagsLoading,
    error: tagsError,
    data: tagsData,
  } = useGetTags();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    let newTag = newValue;
    if (newValue && newValue.indexOf('Add "') !== -1) {
      newTag = newValue.split('Add "')[1].substr(0, newValue.split('Add "')[1].lastIndexOf('"'));
    }
    setValue(newTag);
  };

  const handleSubmit = () => {
    const variables: CreateTagMutationVariables = {
      module: includeModule ? module : null,
      tag: {
        parentType,
        parentId,
        value,
      },
    };
    createTag({ variables });
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setValue('');
    setModalOpen(false);
  };

  if (data?.createTag) {
    if (onTagCreated) {
      onTagCreated(data.createTag);
    }
    setValue('');
    reset();
  }

  let classIndex = 0;

  const getClassFromIndex = () => {
    classIndex += 1;
    const index = classIndex % miscColors.length;
    return miscColors[index];
  };

  const handleDelete = () => true;

  const existingTags = tags.map((tag) => tag.value);

  const userCanEdit = userProfile.hasAnyPermission(['Tags.Edit']);

  return (
    <>
      <div className={classes.chips}>
        {tags
          .slice()
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((y) => (
            <Chip
              style={getClassFromIndex()}
              key={y.id}
              label={y.value}
              color="primary"
              onDelete={userCanEdit ? handleDelete : undefined}
              deleteIcon={userCanEdit ? (
                <DeleteTagControl
                  tagLabel={y.value}
                  key={y.id}
                  tagId={y.id}
                  onTagDeleted={onTagDeleted}
                />
              ) : undefined}
            />
          ))}
        {userCanEdit && (
          <Tooltip title="Add a tag">
            <Button onClick={handleModalOpen} aria-label="add-tag">+ add tag</Button>
          </Tooltip>
        )}
      </div>
      {error && <ErrorDisplay error={error} />}
      <Dialog fullWidth maxWidth="xs" open={modalOpen} onClose={handleModalClose} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">Add tag</DialogTitle>
        <DialogContent>
          <Grid
            item
            xs={12}
            className="tillr-form-grid-element"
          >
            {tagsLoading && <ActivityIndicator />}
            {tagsError && <ErrorDisplay error={tagsError} />}
            {tagsData?.tags && (
              <>
                <Typography variant="body1" display="block">
                  Choose from an existing tag, or add a new tag.
                </Typography>
                <Autocomplete
                  // TODO: Move back to TagAutosuggest. Causes an error
                  // 'cannot read property 'focus' of null'
                  // at the moment and can't get my head around it right now.
                  // Think it's something to do with needing to use a forwardRef.
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  disableClearable
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push(`Add "${params.inputValue}"`);
                    }
                    return filtered;
                  }}
                  value={value}
                  id="tag-suggest"
                  options={tagsData.tags.filter((tag) => !existingTags.includes(tag))}
                  onChange={handleChange}
                  renderInput={(params: any) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Tag"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={loading || !value || !value.length} color="primary">
            Add and close
          </Button>
          {loading && <span>Adding...</span>}
        </DialogActions>
      </Dialog>
    </>
  );
}
