import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

interface IFolder {
  id: string;
  name: string;
}

interface IProps {
  folderId: string;
  folderName: string;
  ancestors: IFolder[];
}

const libraryHomeFolderId = '00000000-0000-0000-0000-000000000000';

export default function LibraryBreadcrumbs({ folderId, folderName, ancestors }: IProps) {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="library-current-location">
      {ancestors.slice().reverse().map((x) => (
        <Link
          key={x.id}
          color="inherit"
          component={RouterLink}
          to={`/library/${x.id}`}
          className={classes.link}
        >
          {x.id === libraryHomeFolderId && <HomeIcon className={classes.icon} />}
          {x.name}
        </Link>
      ))}
      <Typography color="textPrimary" className={classes.link}>
        {folderId === libraryHomeFolderId && <HomeIcon className={classes.icon} />}
        {folderName}
      </Typography>
    </Breadcrumbs>
  );
}
