import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { getFileUrl } from '../../utils';
import { IAttachment } from './IAttachment';
import NetworkStatusContext from '../../NetworkStatusContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  attachmentLinks: {
    color: 'black',
    textDecoration: 'none',
    fontWeight: 500,
    lineHeight: 1.75,
    '&:hover': {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    '&::before': {
      content: '" - "',
    },
    '& span': {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500,
      lineHeight: 1.75,
    },
  },
  disabledLinks: {
    color: '#666',
    fontWeight: 500,
    lineHeight: 1.75,
    '&::before': {
      content: '" - "',
    },
  },
  block: {
    margin: 0,
  },
}));

interface IProps {
  downloadAttachmentUrlTemplate: string;
  attachment: IAttachment;
}

export default function DownloadAttachmentControl(props: IProps) {
  const networkStatusContext = useContext(NetworkStatusContext);
  const classes = useStyles();
  const { downloadAttachmentUrlTemplate, attachment } = props;

  const downloadFileUrl = getFileUrl(downloadAttachmentUrlTemplate,
    attachment.blobStorageId, attachment.fileName);

  return (
    <p className={classes.block}>
      {networkStatusContext ? (
        <a
          href={downloadFileUrl}
          className={classes.attachmentLinks}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Download</span>
        </a>
      ) : (
        <span
          className={classes.disabledLinks}
        >
          <span>Download (connection lost)</span>
        </span>
      )}
    </p>
  );
}
