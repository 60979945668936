import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useGetTenantReports } from '../../../apollo-hooks';
import ActivityIndicator from '../../ActivityIndicator';
import DataTable from '../../DataViews/DataTable';
import ErrorDisplay from '../../ErrorDisplay';
import AdminBreadcrumbs from '../AdminBreadcrumbs';
import AdminNavigation from '../AdminNavigation';
import TitlePrefix from '../../Shared/TitlePrefix';

export default function Reports() {
  const { loading, error, data } = useGetTenantReports();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Reports
            </>
          </Typography>
          <AdminBreadcrumbs title="Reports" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <Divider className="divider" />

      <AdminNavigation path="/admin/reports" />

      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.tenantReports && (
        <DataTable data={data.tenantReports} itemRoute="/reports/{id}" />
      )}
    </>
  );
}
