import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import HelpText from './shared/HelpText';
import ElementInfo from './shared/ElementInfo';
import { validationStyles, requiredValidation, FieldValidationMessage } from './shared/Validation';
import { IComponentProps } from '../IComponentProps';
import withComponentPropsMemo from '../withComponentPropsMemo';

function TextArea(props: IComponentProps) {
  const validationClasses = validationStyles();
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.value);
    }
  };

  return (
    <>
      { field.className?.match(/clear/) && <hr className="tillr-form-grid-element tillr-form-grid-element--break" /> }
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={field.className?.match(/pull-right/) ? 'tillr-form-grid-element tillr-form-grid-element--align-right' : 'tillr-form-grid-element'}
      >
        <TextField
          id={field.key}
          name={field.key}
          defaultValue={modelValue || field.defaultValue}
          label={field.templateOptions?.label}
          variant="outlined"
          placeholder={field.templateOptions?.placeholder}
          onChange={handleChange}
          required={!readonly && field.templateOptions?.required}
          disabled={readonly || field.templateOptions?.disabled}
          InputLabelProps={{
            shrink: !!(
              modelValue
              || field.templateOptions?.placeholder?.length
              || field.defaultValue?.length
            ),
            classes: {
              asterisk: validationClasses.asterisk,
            },
          }}
          fullWidth
          multiline
          rows={4}
          rowsMax={8}
          inputRef={register(requiredValidation(field.templateOptions?.required))}
          error={!!errors[field.key!]}
          InputProps={{
            endAdornment: <ElementInfo data={field.data} />,
          }}
        />
        {errors[field.key!] && <FieldValidationMessage message={errors[field.key!].message} />}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(TextArea);
