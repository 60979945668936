import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetForms } from '../../apollo-hooks';
import { FormFilterBy, FormOrderBy, FormPaginationPropsType, PaginationOrder } from '../../tillr-graphql';
import DataTable from '../DataViews/DataTable';
import ErrorDisplay from '../ErrorDisplay';
import FilterControl, { FilterType, IFilter } from '../Filtering/FilterControl';
import ModuleContext from '../Modules/ModuleContext';
import ModuleLinkButton from '../Modules/ModuleLinkButton';
import PaginationControl from '../Pagination/PaginationControl';
import PreviousNextControl from '../Pagination/PreviousNextControl';
import { getSiteModuleRoute } from '../route-utils';
import ActionButtons from '../Shared/ActionButtons';
import HorizontalLine from '../Shared/HorizontalLine';
import ListControls from '../Shared/ListControls';
import ProgressBar from '../Shared/ProgressBar';
import SiteContext from '../Sites/SiteContext';
import FormBreadcrumbs from './FormBreadcrumbs';

const filterTypeMappings = new Map([
  [FormFilterBy.Name, FilterType.String],
  [FormFilterBy.CreatedDateTime, FilterType.Date],
]);

export default function Forms(props: RouteComponentProps<{ formTemplateId: string }>) {
  const { match: { params: { formTemplateId } } } = props;

  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(FormOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[2],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[1],
  };

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const {
    loading, error, data, refetch, fetchMore,
  } = useGetForms({
    siteId, module, formTemplateId, paginationProps,
  });

  const handleChangeFilter = (nextFilter: IFilter<FormFilterBy>) => {
    refetch({ filterProps: { filters: [nextFilter] } });
  };

  const handleChangePaginationProps = (nextPaginationProps: FormPaginationPropsType) => {
    refetch({ paginationProps: nextPaginationProps });
  };

  const handleFetchPrevious = () => {
    fetchMore({ variables: { before: data?.forms?.startCursor } });
  };

  const handleFetchNext = () => {
    fetchMore({ variables: { after: data?.forms?.endCursor } });
  };

  return (
    <>
      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {data?.formTemplate && data?.forms && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">{data.formTemplate.name}</Typography>
              <FormBreadcrumbs title={data.formTemplate.name} />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          <ActionButtons permission={['Forms.Edit']}>
            <ModuleLinkButton
              to={`/form-templates/${formTemplateId}/forms/add`}
              aria-label="add"
              startIcon={<AddIcon />}
              tooltip={`Create new ${data.formTemplate.name}`}
            >
              Create new
            </ModuleLinkButton>
          </ActionButtons>
          <Paper>
            <ListControls>
              <PaginationControl<FormOrderBy>
                initialProps={paginationProps}
                options={paginationOptions}
                onRefetch={handleChangePaginationProps}
              />
              <FilterControl<FormFilterBy>
                filterByValues={Object.values(FormFilterBy)}
                filterTypeMappings={filterTypeMappings}
                onChange={handleChangeFilter}
              />
              <PreviousNextControl
                hasPreviousPage={data.forms.hasPreviousPage}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={data.forms.hasNextPage}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
            <HorizontalLine margin={[1, 0, 1, 0]} />
            <DataTable
              data={data.forms.items}
              itemRoute={getSiteModuleRoute(siteId, module, 'forms/{id}')}
            />
            <ListControls>
              <PreviousNextControl
                hasPreviousPage={data.forms.hasPreviousPage}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={data.forms.hasNextPage}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
          </Paper>
        </>
      )}
    </>
  );
}
