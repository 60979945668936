import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import UserProfileContext from '../../UserProfileContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginRight: 0,
    '& button': {
      margin: theme.spacing(0.25),
    },
    '& a': {
      margin: theme.spacing(0.25),
    },
  },
  'inner-margin': {
    margin: theme.spacing(0, 0, 2, 0),
  },
  'no-inner-margin': {
    margin: theme.spacing(0),
  },
}));

interface IProps {
  children: React.ReactNode;
  margin?: 'no-inner-margin';
  permission?: string[];
}

export default function ActionButtons(props: IProps) {
  const classes = useStyles();
  const userProfile = useContext(UserProfileContext);
  const { children, margin = 'inner-margin', permission = [] } = props;
  if (permission.length && !userProfile?.hasEveryPermission(permission)) {
    return (
      <>
      </>
    );
  }
  return (
    <div className={clsx(classes.root, classes[margin])}>
      {children}
    </div>
  );
}
