import { useContext } from 'react';
import UserProfileContext from '../../UserProfileContext';

/*
Returns an array of values:
  1. A string stating the tenant type
  This is of a special type, which will change as we go along, or 'tillr'.
  Special types include 'squirrel' and 'systemconcepts'. 'tillr' is any non-special tenant.
  2. A boolean stating whether the tenant gets the Dashboard or not
*/
const useDetectTenantType = () => {
  const userProfile = useContext(UserProfileContext);
  const tenantName = userProfile?.tenantName;

  // User is not logged in
  if (!userProfile) {
    // Is it Vet Partners?
    if (
      window.location.hostname.match(/vetpartners/) // demo
      || window.location.hostname.match(/squirrel/) // prod
    ) {
      return ['squirrel', true];
    }
    // Is it OBSL?
    if (window.location.hostname.match(/obsl\.tillr/)) {
      return ['systemconcepts', false];
    }
    // Is it the property management tenant?
    // if (window.location.hostname.match(/propertymanagement/)) {
    //   return ['propertymanagement', true, 'Property Management'];
    // }
  }

  // User is logged in
  if (userProfile) {
    // Is it Vet Partners?
    if (tenantName?.toLowerCase().match('vetpartners')) {
      return ['squirrel', true];
    }
    // Is it OBSL?
    if (tenantName?.toLowerCase().match('obsl')) {
      return ['systemconcepts', false];
    }
    // Is it the property management tenant?
    // if (tenantName?.toLowerCase().match('property management')) {
    //   return ['propertymanagement', true, 'Property Management'];
    // }
  }

  return ['tillr', true];
};

export default useDetectTenantType;
