import CssBaseline from '@material-ui/core/CssBaseline';
import { useNetworkStatus } from 'react-network-status';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
// eslint-disable-next-line import/extensions
import useGetTheme from '../../themes/useGetTheme';
import Content from '../Content/Content';
import Nav from '../Nav/Nav';
import OfflineMessage from '../Shared/OfflineMessage';
import NetworkStatusContext from '../../NetworkStatusContext';
import { NetworkConfig } from '../../NetworkStatus';

export default function Layout() {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [networkStatus, setNetworkStatus] = useState<boolean>(true);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const theme = useGetTheme();

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NetworkConfig);

  return (
    <>
      <CssBaseline />
      <div className="tillr">
        <MuiThemeProvider theme={theme}>
          <NetworkStatusContext.Provider value={networkStatus}>
            <OfflineMessage />
            <Nav
              drawerOpen={drawerOpen}
              toggleDrawer={toggleDrawer}
            />
            <Content
              drawerOpen={drawerOpen}
              toggleDrawerOpen={toggleDrawer}
            />
          </NetworkStatusContext.Provider>
        </MuiThemeProvider>
      </div>
    </>
  );
}
