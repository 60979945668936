/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSiteModuleRoute } from '../route-utils';
import LinkButton from '../Shared/LinkButton';

interface IProps {
  to: string;
  'aria-label'?: string;
  startIcon?: React.ReactNode;
  tooltip?: string;
}

export default function ModuleLinkButton(props: React.PropsWithChildren<IProps>) {
  const { to, ...rest } = props;
  return <LinkButton to={useSiteModuleRoute(to.toString())} {...rest} />;
}
