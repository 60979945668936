import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
// eslint-disable-next-line import/extensions
import { IAppTheme } from '../../themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) => createStyles({
  root: {
    margin: theme.spacing(1, 0, 2, 0),
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(2),
    '& span': {
      color: theme.mandatory?.color,
    },
  },
}));

export default function MandatoryFieldMessage() {
  const classes = useStyles();
  return (
    <Typography className={classes.root} variant="body2">
      <span>* </span>
      Denotes mandatory field.
    </Typography>
  );
}
