import React, { PropsWithChildren, useContext, useState } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router';
import UserProfileContext from '../../UserProfileContext';
import ModuleContext from '../Modules/ModuleContext';
import { getSiteModuleRoute } from '../route-utils';
import SiteContext from './SiteContext';
import { getStartUpRoute } from '../../utils';

export default function SiteManager(props: PropsWithChildren<{}>) {
  const { children } = props;

  const defaultSiteId = 0;
  const moduleContext = useContext(ModuleContext);
  const userProfile = useContext(UserProfileContext)!;
  const [siteId, setSiteId] = useState(defaultSiteId);

  const routeMatch = useRouteMatch<{ siteId: string, 1: string }>();
  const { params: { siteId: siteIdRouteParam, 1: path } } = routeMatch;
  const siteIdFromRoute = Number(siteIdRouteParam);
  const queryString = useLocation().search;

  if (userProfile && siteId === defaultSiteId) {
    // User logged in

    if (Number.isNaN(siteIdFromRoute)) {
      const startUpRoute = getStartUpRoute();
      if (startUpRoute) {
        // User needs to be redirected to initial requested route
        try {
          const split = startUpRoute.split('site-')[1];
          const site = Number(split.substring(0, split.indexOf('/')));
          if (!Number.isNaN(site) && userProfile.siteIds.includes(site)) {
            setSiteId(site);
          } else {
            // Something has gone wrong, or they're not allowed to access the site
            // so send them to a site that they can access
            setSiteId(userProfile.siteIds[0]);
          }
        } catch (e) {
          setSiteId(userProfile.siteIds[0]);
        }
      } else {
        setSiteId(userProfile.siteIds[0]);
      }
    } else {
      setSiteId(userProfile.siteIds.includes(siteIdFromRoute)
        ? siteIdFromRoute : userProfile.siteIds[0]);
    }
  } else if (!userProfile && siteId !== defaultSiteId) {
    // User logged out
    setSiteId(defaultSiteId);
  }

  if (siteIdFromRoute && siteId && siteIdFromRoute !== siteId) {
    // User is on a site route, and site has changed
    const module = moduleContext?.module || userProfile.modules[0];
    return <Redirect to={getSiteModuleRoute(siteId, module, path + queryString)} />;
  }

  return (
    <SiteContext.Provider value={{ siteId, setSiteId }}>
      {children}
    </SiteContext.Provider>
  );
}
