import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGetFormTemplateRules } from '../../../apollo-hooks';
import ActivityIndicator from '../../ActivityIndicator';
import Detail from '../../DataViews/Detail';
import ErrorDisplay from '../../ErrorDisplay';
import ModuleContext from '../../Modules/ModuleContext';
import SiteContext from '../../Sites/SiteContext';

export default function Rules(props: RouteComponentProps<{ formTemplateId: string }>) {
  const { match: { params: { formTemplateId } } } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetFormTemplateRules({ siteId, module, id: formTemplateId });

  return (
    <div>
      <Typography variant="h2" noWrap>Rules</Typography>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data && (data.formTemplate
        ? <Detail data={data.formTemplate} />
        : !error && <p><i>Not found</i></p>)}
    </div>
  );
}
