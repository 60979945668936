import React, { useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { validationStyles, getRuleMessage, FieldValidationMessage } from './shared/Validation';
import HelpText from './shared/HelpText';
import ElementInfo from './shared/ElementInfo';
import { IComponentProps } from '../IComponentProps';

export default function RadioButton(props: IComponentProps) {
  const validationClasses = validationStyles();
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    trigger,
  } = props;

  useEffect(() => {
    register({ name: field.key }, { required: { value: field.templateOptions?.required, message: getRuleMessage('required') } });
    setValue(field.key!, modelValue || '');
    if (modelValue) {
      trigger(field.key!);
    }
  }, [register, setValue, trigger, field, modelValue]);

  if (!field.templateOptions?.options?.length) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: Radio without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.value);
      setValue(field.key!, event.target.value || '');
      trigger(field.key!);
    }
  };

  return (
    <>
      { field.className?.match(/clear/) && <hr className="tillr-form-grid-element tillr-form-grid-element--break" /> }
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={field.className?.match(/pull-right/) ? 'tillr-form-grid-element tillr-form-grid-element--align-right' : 'tillr-form-grid-element'}
      >
        <FormControl
          component="fieldset"
          disabled={readonly}
        >
          <FormLabel
            id={field.key}
            component="legend"
            required={!readonly && field.templateOptions?.required}
            classes={{
              asterisk: validationClasses.asterisk,
            }}
          >
            {field.templateOptions?.label}
            <ElementInfo inline data={field.data} />
          </FormLabel>
          <RadioGroup
            aria-label={field.templateOptions.label}
            onChange={handleChange}
            value={typeof modelValue !== 'undefined' ? modelValue : ''}
            id={field.key}
          >
            {field.templateOptions.options.map((option, i) => {
              const htmlId = `${field.key}_${i}`;
              const key = `label_${i}`;
              const optionValue = option.value || option.name;
              return (
                <FormControlLabel
                  key={key}
                  value={optionValue}
                  control={<Radio id={htmlId} />}
                  label={option.name}
                />
              );
            })}
          </RadioGroup>
          {errors[field.key!] && <FieldValidationMessage className="non-input" message={errors[field.key!].message} />}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
}
