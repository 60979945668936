import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React, { useContext } from 'react';
import { useGetMyTask, useUpdateMyTask } from '../../../apollo-hooks';
import FormlyForm from '../../../formly/FormlyForm';
import { IModel } from '../../../formly/IModel';
import getStaticTemplate from '../../../formly/staticTemplates';
import { AssigneeType, UpdateMyTaskMutationVariables, UpdateTaskInputType } from '../../../tillr-graphql';
import ModuleContext from '../../Modules/ModuleContext';
import ModuleRedirect from '../../Modules/ModuleRedirect';
import SiteContext from '../../Sites/SiteContext';

const useStyles = makeStyles(() => createStyles({
  dialog: {
    minWidth: '300px',
  },
}));

interface ITaskProps {
  taskId: string;
}

export default function QuickTask(props: ITaskProps) {
  const classes = useStyles();
  const { taskId } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function QuickStatusForm() {
    const { siteId } = useContext(SiteContext)!;
    const { module } = useContext(ModuleContext)!;
    const queryState = useGetMyTask({ siteId, module, id: taskId });
    const [updateMyTask, mutationState] = useUpdateMyTask({ siteId, module, id: taskId });

    if (mutationState.data?.updateMyTask) {
      return <ModuleRedirect to="/tasks?view=list" />;
    }

    const handleSubmit = (model: IModel) => {
      if (queryState.data?.myTask) {
        const { myTask } = queryState.data;
        const task: UpdateTaskInputType = {
          id: taskId,
          name: myTask.name,
          description: myTask.description,
          open: model.open,
          completedDate: model.completedDate,
          assignees: myTask.assignedToUsers
            .map((x) => ({ assigneeId: x.id, assigneeType: AssigneeType.User }))
            .concat(myTask.assignedToTeams
              .map((x) => ({ assigneeId: x.id, assigneeType: AssigneeType.Team }))),
        };

        const mutationVariables: UpdateMyTaskMutationVariables = { siteId, module, task };
        updateMyTask({ variables: mutationVariables });
        handleClose();
      }
      return false;
    };

    const config = getStaticTemplate('task-quick-status');

    const formattedConfig = {
      fields: config.fields.map((field) => {
        if (field.key === 'completedDate') {
          if (queryState.data?.myTask?.createdDateTime) {
            Object.defineProperty(field.data, 'minDate', {
              value: queryState.data.myTask.createdDateTime,
              writable: true,
              enumerable: true,
            });
          }
          Object.defineProperty(field.data, 'maxDate', {
            value: new Date(),
            writable: true,
            enumerable: true,
          });
          return field;
        }
        return field;
      }),
      settings: config.settings,
    };

    let formattedTask = {};
    if (queryState.data?.myTask) {
      formattedTask = { ...queryState.data.myTask };
      Object.defineProperty(formattedTask, 'completedDate', {
        value: new Date(),
        writable: true,
        enumerable: true,
      });
    }

    return (
      <FormlyForm
        formId={taskId}
        mode="create"
        config={formattedConfig}
        model={formattedTask}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        modal
      />
    );
  }

  return (
    <>
      <Tooltip title="Change task status">
        <IconButton
          aria-label="done"
          onClick={handleClickOpen}
          color="secondary"
        >
          <CheckBoxOutlineBlankIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change task status</DialogTitle>
        <DialogContent className={classes.dialog}>
          <QuickStatusForm />
        </DialogContent>
      </Dialog>
    </>
  );
}
