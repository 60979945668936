import AvatarGroup from '@material-ui/lab/AvatarGroup';
import React from 'react';
import UserAvatar from '../../Shared/UserAvatar';

interface IAssignee {
  id: string;
  name: string;
}

interface IAssignees {
  users: IAssignee[];
  teams: IAssignee[];
}

export default function AssigneesSummary(props: IAssignees) {
  const { users, teams } = props;
  const assignees = users.concat(teams);
  return (
    <>
      <AvatarGroup max={2}>
        {assignees.map((assignee) => (
          <UserAvatar
            key={assignee.id}
            name={assignee.name}
            id={assignee.id}
          />
        ))}
      </AvatarGroup>
    </>
  );
}
