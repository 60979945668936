import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetMyTask } from '../../apollo-hooks';
import FormlyForm from '../../formly/FormlyForm';
import getStaticTemplate from '../../formly/staticTemplates';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import ModuleLinkButton from '../Modules/ModuleLinkButton';
import NotesList from '../Notes/NotesList';
import HorizontalLine from '../Shared/HorizontalLine';
import ProgressBar from '../Shared/ProgressBar';
import SiteContext from '../Sites/SiteContext';
import TasksBreadcrumbs from './TasksBreadcrumbs';
import { formatTaskAssignees } from './utils';
import UserProfileContext from '../../UserProfileContext';
import ActionButtons from '../Shared/ActionButtons';

interface IAssignee {
  id: string;
  name: string;
}

interface IProps {
  children: React.ReactNode;
  creator: string;
  assignees: IAssignee[];
}

export default function Task(props: RouteComponentProps<{ taskId: string }>) {
  const { match: { params: { taskId } } } = props;
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const userProfile = useContext(UserProfileContext)!;
  const {
    loading,
    error,
    data,
    refetch,
  } = useGetMyTask({ siteId, module, id: taskId });

  const handleNoteCreated = () => {
    refetch();
  };

  const config = getStaticTemplate('task');
  return (
    <>
      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {data?.myTask && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">
                Tasks
              </Typography>
              <TasksBreadcrumbs title={data?.myTask?.name} />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          {/* TODO: May have to change if we start using Teams again */}
          {userProfile.canEditTask(data.myTask.createdByUser.id, data.myTask.assignedToUsers) && (
            <ActionButtons permission={['Tasks.Edit']}>
              <ModuleLinkButton
                to={`/tasks/${taskId}/edit`}
                aria-label="edit"
                startIcon={<EditIcon />}
                tooltip="Edit task"
              >
                Edit
              </ModuleLinkButton>
            </ActionButtons>
          )}
          <FormlyForm
            formId={taskId}
            mode="readonly"
            config={config}
            model={formatTaskAssignees(data.myTask)}
          />
          <HorizontalLine margin={[4, 0, 4, 0]} />
          <NotesList permission={['Tasks.Edit']} onNoteAdded={handleNoteCreated} taskId={taskId} notes={data.myTask.notes} />
        </>
      )}
    </>
  );
}
