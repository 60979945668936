import { ApolloError } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { extractErrors } from '../utils';
import NetworkStatusContext from '../NetworkStatusContext';
import Alert from './Shared/Alert';

export default function ErrorDisplay(props: {
  error?: ApolloError | string | null | undefined,
  errorMessage?: string,
}) {
  const networkStatusContext = useContext(NetworkStatusContext);
  const { error, errorMessage } = props;
  const errors = error && typeof error !== 'string' ? extractErrors(error) : [error];

  if (errorMessage) {
    errors.push(errorMessage);
  }

  if (!errors.length) {
    return null;
  }

  if (!networkStatusContext) {
    return (
      <Alert type="network" />
    );
  }

  return (
    <>
      <Alert type="warning">
        <Typography variant="h5" display="block">
          Something has gone wrong
        </Typography>
        <Typography variant="body1" display="block">
          {errors.map((x) => <span key={x}>{x}</span>)}
        </Typography>
      </Alert>
    </>
  );
}
