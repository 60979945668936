import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ModuleContext from '../Modules/ModuleContext';
import { getSiteModuleRoute } from '../route-utils';
import SiteContext from '../Sites/SiteContext';

interface IProps {
  title?: string;
}

export default function TasksBreadcrumbs(props: IProps) {
  const { title } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" component={RouterLink} to="/">
        Dashboard
      </Link>
      {title !== 'Tasks' && (
        <Link
          color="inherit"
          component={RouterLink}
          to={getSiteModuleRoute(siteId, module, 'tasks?view=list')}
        >
          Tasks
        </Link>
      )}
      <Typography color="textPrimary">{title}</Typography>
    </Breadcrumbs>
  );
}
