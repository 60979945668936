import { Box, Typography } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { addMonths, isBefore } from 'date-fns';
import ErrorDisplay from '../../ErrorDisplay';
import { convertDataValueToString, DataColumn, DataRow } from '../utils';
import { IModel } from '../../../formly/IModel';

interface IProps {
  inputParametersDefinition: DataColumn[];
  inputParameters: IModel;
  columns: DataColumn[];
  data: DataRow[];
  groupByColumnName: string;
  isGroupByMonth: boolean;
  countColumnName: string | undefined;
}

export default function BarChartView(props: IProps) {
  const {
    inputParametersDefinition,
    inputParameters,
    columns,
    data,
    groupByColumnName,
    isGroupByMonth,
    countColumnName,
  } = props;

  const groupByColumnType = columns.find((x) => x.name === groupByColumnName)?.type;
  if (!groupByColumnType) {
    return <ErrorDisplay errorMessage={`[${groupByColumnName}] is not a valid column.`} />;
  }
  if (countColumnName) {
    const countColumnType = columns.find((x) => x.name === countColumnName)?.type;
    if (countColumnType !== 'number') {
      return <ErrorDisplay errorMessage={`[${countColumnName}] is not a valid count column.`} />;
    }
  }

  const results: Record<string, number> = {};
  if (isGroupByMonth) {
    const [startMonthParameter, endMonthParameter] = inputParametersDefinition.filter(
      (x) => x.type === 'datepicker',
    );

    const startMonthStringValue = inputParameters[startMonthParameter.name];
    const endMonthStringValue = inputParameters[endMonthParameter.name];

    if (startMonthStringValue && endMonthStringValue) {
      const startMonthValue = new Date(startMonthStringValue);
      const endMonthValue = new Date(endMonthStringValue);

      if (isBefore(startMonthValue, endMonthValue)) {
        const endMonthKey = convertDataValueToString(
          endMonthValue,
          groupByColumnType,
          isGroupByMonth,
        );

        let testMonthValue = startMonthValue;
        let testMonthKey = convertDataValueToString(
          testMonthValue,
          groupByColumnType,
          isGroupByMonth,
        ) as string;

        // Insert zeroes for every month
        while (testMonthKey !== endMonthKey) {
          results[testMonthKey] = 0;
          testMonthValue = addMonths(testMonthValue, 1);
          testMonthKey = convertDataValueToString(
            testMonthValue,
            groupByColumnType,
            isGroupByMonth,
          ) as string;
        }
      }
    }
  }

  data.forEach((row) => {
    const key = convertDataValueToString(row[groupByColumnName], groupByColumnType, isGroupByMonth);
    const count = countColumnName ? (row[countColumnName] as number) : 1;
    if (!results[key]) {
      results[key] = count;
    } else {
      results[key] += count;
    }
  });

  const barData = Object.keys(results).map((x) => ({
    id: x,
    count: results[x],
  }));

  const fills = ['', 'dots', 'lines'];

  return (
    <Box height={400} padding={3}>
      <Typography variant="h2">{groupByColumnName}</Typography>
      <ResponsiveBar
        data={barData}
        keys={['count']}
        indexBy="id"
        margin={{
          top: 50,
          right: 130,
          bottom: 50,
          left: 60,
        }}
        colors={{ scheme: 'category10' }}
        fill={barData.map((x, i) => ({
          match: { id: x.id },
          id: fills[Math.floor(i / 10) % fills.length],
        }))}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: groupByColumnName,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </Box>
  );
}
