import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetForm } from '../../apollo-hooks';
import FormlyForm from '../../formly/FormlyForm';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';
import ModuleLinkButton from '../Modules/ModuleLinkButton';
import ActionButtons from '../Shared/ActionButtons';
import HorizontalLine from '../Shared/HorizontalLine';
import ProgressBar from '../Shared/ProgressBar';
import SiteContext from '../Sites/SiteContext';
import DuplicateForm from './DuplicateForm';
import FormBreadcrumbs from './FormBreadcrumbs';

export default function Form(props: RouteComponentProps<{ formId: string }>) {
  const { match: { params: { formId } } } = props;
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetForm({ siteId, module, id: formId });
  const [editPermissions, setEditPermissions] = useState(['Forms.Edit']);

  useEffect(() => {
    if (data?.form?.isConfidential) {
      setEditPermissions((previous) => {
        const newPermissions = [
          ...previous,
          'Forms.EditConfidential',
        ];
        return newPermissions;
      });
    }
  }, [data, setEditPermissions]);

  return (
    <>
      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {data && (data.form ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" display="block">
                {data.form.name}
              </Typography>
              {/* TODO: Replace 'Form Instances' with the Form Template Name */}
              <FormBreadcrumbs
                title={data.form.name}
                subtitle="Form Instances"
                sublink={`${data.form.formTemplate.activeFormTemplateId}/forms`}
              />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          <ActionButtons permission={editPermissions}>
            <DuplicateForm instance={data.form} />
            <ModuleLinkButton
              to={`/forms/${formId}/edit`}
              aria-label="edit"
              startIcon={<EditIcon />}
              tooltip="Edit form"
            >
              Edit
            </ModuleLinkButton>
          </ActionButtons>
          <FormlyForm
            formId={data.form.id}
            mode="readonly"
            config={JSON.parse(data.form.template)}
            model={JSON.parse(data.form.formDataJson)}
          />
        </>
      ) : !error && <p><i>Not found</i></p>)}
    </>
  );
}
