import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IAttachment } from '../../components/Attachments/IAttachment';
import ManageAttachmentsControl from '../../components/Attachments/ManageAttachmentsControl';
import { ParentType } from '../../tillr-graphql';
import { IComponentProps } from '../IComponentProps';
import withComponentPropsMemo from '../withComponentPropsMemo';

// This is the explicit model for persisting in FormData json
interface IAttachmentItem {
  id: string;
  fileName: string;
  name: string;
}

function Attachments(props: IComponentProps) {
  const {
    formId, readonly, field, modelValue, onValueUpdate,
  } = props;

  if (!formId) {
    throw new Error('formId is required for Attachments field.');
  }

  const attachments = (modelValue as IAttachmentItem[] || [])
    .map((attachmentItem) => ({ blobStorageId: attachmentItem.id, ...attachmentItem }));

  const handleAttachmentsUpdated = (updatedAttachments: IAttachment[]) => {
    const nextAttachments = updatedAttachments
      // Ensure only properties we want persisted are added
      .map((x) => ({ id: x.blobStorageId, fileName: x.fileName, name: x.name }));
    if (field.key) {
      onValueUpdate(field.key, nextAttachments);
    }
  };

  const parentType = field.data?.parentTypeForAttachments
    ? field.data.parentTypeForAttachments : ParentType.Form;

  return (
    <>
      { field.className?.match(/clear/) && <hr className="tillr-form-grid-element tillr-form-grid-element--break" /> }
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={field.className?.match(/pull-right/) ? 'tillr-form-grid-element tillr-form-grid-element--align-right' : 'tillr-form-grid-element'}
      >
        <h2>Attachments</h2>
        <ManageAttachmentsControl
          parentId={formId}
          parentType={parentType}
          attachments={attachments}
          enableCreate={!(readonly)}
          onAttachmentsUpdated={handleAttachmentsUpdated}
        />
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Attachments);
