import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetAttachmentDetail } from '../../apollo-hooks';
import ActivityIndicator from '../ActivityIndicator';
import DownloadAttachmentControl from '../Attachments/DownloadAttachmentControl';
import Detail from '../DataViews/Detail';
import ErrorDisplay from '../ErrorDisplay';
import ModuleContext from '../Modules/ModuleContext';

export default function FileAttachment(props: RouteComponentProps<{ attachmentId: string }>) {
  const { match: { params: { attachmentId } } } = props;

  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetAttachmentDetail({ module, id: attachmentId });

  return (
    <div>
      <h2>Attachment</h2>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data && (data.attachment
        ? (
          <>
            <DownloadAttachmentControl
              downloadAttachmentUrlTemplate={data.downloadAttachmentUrlTemplate!}
              attachment={data.attachment}
            />
            <Detail data={data.attachment} />
          </>
        )
        : !error && <p><i>Not found</i></p>)}
    </div>
  );
}
