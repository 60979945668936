import React, { useEffect, useContext } from 'react';
import { schemePaired } from 'd3-scale-chromatic';
import format from 'date-fns/format';
import { isEqual, set, subDays, addDays, isAfter } from 'date-fns';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Chip, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { IAppTheme } from '../../themes/useGetTheme';
import useRunReportToJson from '../Reports/useRunReportToJson';
import SiteContext from '../Sites/SiteContext';
import ProgressBar from '../Shared/ProgressBar';
import ErrorDisplay from '../ErrorDisplay';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const useStyles = makeStyles((theme: IAppTheme) => ({
  dashboardText: {
    '& h2': {
      fontSize: 22,
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(0, 0, 1, 0),
      borderBottom: 'solid 1px #ccc',
      color: '#111',
    },
    '& p': {
      margin: theme.spacing(0, 0, 2, 0),
      fontSize: 12,
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reported: {
    fontSize: 12,
    lineHeight: 1,
    margin: theme.spacing(0, 0, 2),
    '& strong': {
      fontSize: 22,
      padding: theme.spacing(0, 1),
      color: schemePaired[5],
    },
  },
}));

interface IAssignee {
  id: string;
  name: string;
}
interface ITask {
  id: string;
  name: string;
  referenceNumber: number;
  assignedToUsers: IAssignee[];
  assignedToTeams: IAssignee[];
  createdByFormTemplateName?: null | string;
  open: boolean;
  dueDate: string;
}

interface ITaskData {
  id: string;
  value: number | string | Date;
  children: ITaskData[];
  date?: string;
}

export default function AccidentReport() {
  const classes = useStyles();
  const daysToDisplay = 30;
  let daysDisplayed = 0;
  const today = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  // const today = set(new Date(), {
  //   year: 2021,
  //   month: 2,
  //   date: 26,
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  //   milliseconds: 0,
  // });
  const startDate = subDays(today, daysToDisplay);
  const startColumn = Number(format(startDate, 'i')) - 1;
  const { siteId } = useContext(SiteContext)!;
  const reportId = '20480CFF-0ADA-42D9-B988-99130B20BEC3';
  // const reportId = 'de9466e5-09a2-4cfc-8c5c-1eeb5e80cc13';
  const [runReport, { loading, data, error }] = useRunReportToJson(reportId);

  useEffect(() => {
    runReport({
      '@FromDate': startDate.toISOString(),
      '@ToDate': today.toISOString(),
    });
    // eslint-disable-next-line
  }, [siteId]);

  function getAccidentsForDay(date: Date) : (JSX.Element | null) {
    if (!data) {
      return null;
    }
    const accidents = data.filter((accident) => isEqual(date, set(new Date(accident['Date of accident']), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })));
    if (!accidents.length) {
      return null;
    }
    return (
      <LightTooltip
        title={(
          <>
            <div>
              {`${format(accidents[0]['Date of accident'], 'iiii do LLLL yyyy')}: `}
            </div>
            {accidents.map((accident, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                {`${accident['Practice name']}: ${accident['HSE category of accident']}`}
              </div>
            ))}
          </>
        )}
      >
        <Chip
          className="dashboard-calendar__report"
          style={{ backgroundColor: schemePaired[5] }}
          label={accidents.length}
        />
      </LightTooltip>
    );
  }

  function Day(props: { index: number }): (JSX.Element) {
    const { index } = props;
    if (index < startColumn) {
      return (
        <div className="dashboard-calendar--blank" />
      );
    }
    const date = addDays(startDate, daysDisplayed);
    const day = format(date, 'dd');
    const month = Number(format(date, 'M'));
    daysDisplayed += 1;

    if (isAfter(date, today)) {
      return (
        <div className="dashboard-calendar--blank" />
      );
    }

    const isToday = isEqual(date, today);

    const accidentsForDay = getAccidentsForDay(date);

    if (accidentsForDay) {
      return (
        <div
          className={isToday ? 'dashboard-calendar--today' : ''}
          style={{ backgroundColor: isToday ? schemePaired[10] : schemePaired[month - 1] }}
        >
          <strong>
            {`${day}`}
          </strong>
          {accidentsForDay}
        </div>
      );
    }

    return (
      <Tooltip title={format(date, 'iiii do LLLL yyyy')}>
        <div
          className={isToday ? 'dashboard-calendar--today' : ''}
          style={{ backgroundColor: isToday ? schemePaired[10] : schemePaired[month - 1] }}
        >
          <strong>
            {`${day}`}
          </strong>
        </div>
      </Tooltip>
    );
  }

  const renderChart = () => (
    <>
      <Typography variant="body2" display="block" className={classes.reported}>
        Accidents reported in the last 30 days:
        {data ? (
          <strong>{data.length}</strong>
        ) : (
          <strong>0</strong>
        )}
      </Typography>
      <div className="dashboard-calendar">
        {[...Array(42)].map((item, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <Day
              index={i}
            />
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div>
      <div className={classes.dashboardText}>
        <Typography variant="h2" display="block">
          Accident Reports
        </Typography>
      </div>
      {loading && (
        <Box
          height={400}
          className={classes.loading}
        >
          <ProgressBar />
          <CircularProgress />
        </Box>
      )}
      {error && <ErrorDisplay error={error} />}
      {!loading && !error && renderChart()}
    </div>
  );
}
