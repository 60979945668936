import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorDisplay from '../ErrorDisplay';
import { uploadFiles } from '../../utils';
import ProgressBar from '../Shared/ProgressBar';
import { CreateLibraryFilesMutationVariables } from '../../tillr-graphql';
import { useCreateLibraryFiles } from '../../apollo-hooks';

interface IProps {
  folderId: string;
  folderName: string;
}

export default function AddFiles({ folderId, folderName }: IProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [createLibraryFiles, { loading, data, error }] = useCreateLibraryFiles({ folderId });

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSave = (files: File[]) => {
    setFilesToUpload(files);
  };

  let uploading = false;
  if (filesToUpload.length) {
    if (!loading && !data && !error) {
      const fileNames = Array.from(filesToUpload).map((x) => x.name);
      const variables: CreateLibraryFilesMutationVariables = { folderId, fileNames };
      createLibraryFiles({ variables });
    }

    if (data?.createLibraryFiles) {
      uploading = true;
      const { uploadUrlTemplate, files } = data.createLibraryFiles;
      uploadFiles(
        uploadUrlTemplate,
        files.map((x, i) => ({ id: x.id, file: filesToUpload[i] })),
        (fileIndex: number, percentComplete: number) => {
          // eslint-disable-next-line no-console
          console.log('Upload progress', `File ${fileIndex}`, `${percentComplete.toFixed(2)}%`);
        },
      )
        .then(() => {
          setFilesToUpload([]);
          handleCloseDialog();
        })
        // eslint-disable-next-line no-alert
        .catch((reason: Error) => alert(reason));
    }
  }

  const submitting = loading || uploading;

  const tooltipValue = `Add one or more files under ${folderName}`;

  return (
    <>
      {submitting && (
        <ProgressBar />
      )}
      <Tooltip title={tooltipValue}>
        <Button
          variant="contained"
          startIcon={<AddBoxIcon />}
          onClick={handleOpenDialog}
          color="secondary"
        >
          New file(s)
        </Button>
      </Tooltip>
      {error && <ErrorDisplay error={error} /> /* TODO: Move (how to handel inline errors?) */ }
      <DropzoneDialog
        open={isDialogOpen}
        filesLimit={999}
        showAlerts
        showPreviews={isLargeScreen}
        onSave={handleSave}
        onClose={handleCloseDialog}
        submitButtonText={!submitting ? 'Submit' : 'Uploading...'}
        maxFileSize={629145600}
      />
    </>
  );
}
