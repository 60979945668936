import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LaunchIcon from '@material-ui/icons/Launch';
import React, { useState } from 'react';
import QuickViewModal from '../QuickViewModal';

interface IProps {
  taskId: string;
}

export default function QuickViewButton(props: IProps) {
  const { taskId } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Quick view">
        <IconButton
          onClick={handleClickOpen}
          aria-label="quick-view"
          className="action-button"
        >
          <LaunchIcon />
        </IconButton>
      </Tooltip>
      <QuickViewModal
        taskId={taskId}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}
