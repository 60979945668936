import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useCreateTaskNote } from '../../apollo-hooks';
import { CreateTaskNoteMutationVariables } from '../../tillr-graphql';
import ErrorDisplay from '../ErrorDisplay';
import MagicForm from '../MagicForm';
import ModuleContext from '../Modules/ModuleContext';
import ModuleRedirect from '../Modules/ModuleRedirect';
import SiteContext from '../Sites/SiteContext';

export default function AddTaskNote(props: RouteComponentProps<{ taskId: string }>) {
  const { match: { params: { taskId } } } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createTaskNote, { loading, data, error }] = useCreateTaskNote({
    siteId, module, id: taskId,
  });

  if (data?.createTaskNote) {
    return <ModuleRedirect to={`/tasks/${taskId}`} />;
  }

  const handleSubmit = (model: CreateTaskNoteMutationVariables) => {
    createTaskNote({ variables: model });
  };

  const model: CreateTaskNoteMutationVariables = {
    siteId, module, taskId, note: '',
  };

  return (
    <div>
      <h2>Create task note</h2>
      {error && <ErrorDisplay error={error} />}
      <MagicForm model={model} onSubmit={handleSubmit} submitting={loading} />
    </div>
  );
}
