import { format } from 'date-fns';

export type DataColumn = { name: string; type: string };
export type DataValue = string | number | boolean | Date | null;
export type DataRow = { [k: string]: DataValue };

const getColumnName = (value: string, type: string, columnFormat?: string): string => {
  switch (type) {
    case 'date':
    case 'datetime': {
      return format(new Date(value), columnFormat || 'd MMM yy HH:mm:ss');
    }
    default: {
      return value.toString();
    }
  }
};

const getData = (value: string, type: string) => {
  switch (type) {
    case 'boolean': {
      return value?.toLowerCase() === 'true';
    }
    case 'date':
    case 'datetime': {
      return value ? new Date(value) : null;
    }
    case 'number': {
      return value != null ? Number(value) : null;
    }
    default: {
      return value;
    }
  }
};

const distinct = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

export function parseData(
  data: string[][],
  columns: DataColumn[],
  pivot?: { keyColumn: string; keyColumnFormat?: string; valueColumn: string },
): { data: DataRow[]; columns: DataColumn[] } {
  if (!pivot) {
    return {
      data: data.map((row) =>
        Object.fromEntries(row.map((x, i) => [columns[i].name, getData(x, columns[i].type)])),
      ),
      columns,
    };
  }

  const { keyColumn, keyColumnFormat, valueColumn } = pivot;
  const pivotKeyIndex = columns.findIndex((x) => x.name === keyColumn);
  const pivotKeyType = columns[pivotKeyIndex].type;
  const pivotValueIndex = columns.findIndex((x) => x.name === valueColumn);
  const pivotValueType = columns[pivotValueIndex].type;

  const pivotGroupingColumns = columns.filter(
    (_, i) => i !== pivotKeyIndex && i !== pivotValueIndex,
  );
  const pivotedColumns = pivotGroupingColumns.slice();
  pivotedColumns.push(
    ...data
      .map((x) => getColumnName(x[pivotKeyIndex], pivotKeyType, keyColumnFormat))
      .filter(distinct)
      .map((x) => ({ name: x, type: pivotValueType })),
  );

  const pivotedData: DataRow[] = [];
  data.forEach((row) => {
    const pivotGroupingKey = row
      .filter((_, i) => i !== pivotKeyIndex && i !== pivotValueIndex)
      .map((x, i) => `${pivotGroupingColumns[i].name}=${x}`)
      .join('&');

    let pivotedRow = pivotedData.find((x) => x.pivotGroupingKey === pivotGroupingKey);
    if (!pivotedRow) {
      pivotedRow = Object.fromEntries(
        row
          .filter((_, i) => i !== pivotKeyIndex && i !== pivotValueIndex)
          .map((x, i) => [pivotGroupingColumns[i].name, getData(x, pivotGroupingColumns[i].type)]),
      );
      pivotedRow.pivotGroupingKey = pivotGroupingKey;
      pivotedData.push(pivotedRow);
    }

    const pivotedColumnName = getColumnName(row[pivotKeyIndex], pivotKeyType, keyColumnFormat);
    pivotedRow[pivotedColumnName] = getData(row[pivotValueIndex], pivotValueType);
  });

  return { data: pivotedData, columns: pivotedColumns };
}

export function convertDataValueToString(
  value: DataValue,
  type: string,
  isGroupByMonth: boolean = false,
): string | number {
  switch (type) {
    case 'number': {
      return (value as number) || 0;
    }
    case 'date': {
      return format(value as Date, isGroupByMonth ? 'MMM yy' : 'd MMM yy');
    }
    case 'datetime': {
      return format(value as Date, isGroupByMonth ? 'MMM yy' : 'd MMM yy HH:mm:ss');
    }
    default:
      return value?.toString() || '';
  }
}
