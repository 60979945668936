import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

interface IProps {
  margin: number[];
}

export default function HorizontaLine(props: IProps) {
  const theme = useTheme();
  const { margin } = props;
  // TODO: Got to be a more sensible way to do
  // this in Material UI?
  const size = margin.length < 3 ? [0, 0, 0, 0] : margin;
  const style = {
    margin: theme.spacing(size[0], size[1], size[2], size[3]),
  };

  return (
    <Divider style={style} />
  );
}
