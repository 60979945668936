import { Paper, Tab, Tabs } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  path: string;
}

export default function AdminNavigation(props: IProps) {
  const { path } = props;
  const history = useHistory();
  const [value, setValue] = useState(path);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <Paper
      elevation={0}
      square
    >
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="inherit"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab
          icon={<AccountTreeIcon />}
          label="Sites"
          value="/admin/sites"
        />
        <Tab
          icon={<AccountBoxIcon />}
          label="Users"
          value="/admin/users"
        />
        {/* <Tab
          icon={<ListAltIcon />}
          label="Forms"
          value="/admin/form-templates"
        /> */}
        {/* <Tab
          icon={<ShowChartIcon />}
          label="Reports"
          value="/admin/reports"
        /> */}
      </Tabs>
    </Paper>
  );
}
